import feather from "feather-icons";
import Sidebar from "../Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "../Auth/login";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import profile from "../assets/profile-image.png";

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import "../Account.css";

import { useEffect } from "react";
import apiClient from "../Auth/apiClient"; // Import the custom axios instance

function Account_Info( data ) {
  console.log(data);
  return (
    <div className="account-info">
    <h2 className="head-card mb-4">Account Info</h2>
    <div className="input-group mb-2">
      <div className="input-box" >
        <label className="input-label">Name</label>
        <br />
        <input type="text" placeholder="Enter Name" value={data.data.name} />
      </div>
      {/* <div>
        <label class="input-label">First Name</label>
        <br />
        <input type="text" placeholder="Last Name" />
      </div> */}
      <div className="input-box">
        <label class="input-label">Phone</label>
        <br />
        <input type="text" placeholder="Title" value={data.data.phone} />
      </div>
    </div>
    <div className="d-flex justify-content-between">
      <div>
        <label class="input-label">Email</label>
        <br />
        <input
          className="email-input"
          type="email"
          placeholder="abc@gmail.com"
          value={data.data.email}
          readOnly
        />
      </div>
      <div className="align-content-center">
        <button className="btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <path
              fill="gray"
              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
            />
          </svg>{" "}
          Change Email
        </button>
      </div>
    </div>
    <div className="mt-2">
      <div>
        <label class="input-label">Password</label>
      </div>
      <div>
        <button className="btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <path
              fill="gray"
              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
            />
          </svg>{" "}
          Change Password
        </button>
      </div>
    </div>
    <div className="mt-2">
      <div>
        <label class="input-label">Premission Profile</label>
      </div>
      <div>
        <select>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
        </select>
      </div>
    </div>

    <div style={{display:"none"}} className="credits">
      <div className="credit-item">
        <div>
          <label className="input-label">Email Credit Limit</label>
        </div>
        <div className="input-with-icon">
          <input type="number" placeholder="0" readOnly />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <path
              fill="gray"
              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
            />
          </svg>
        </div>
      </div>
      <div className="credit-item">
        <div>
          <label className="input-label">
            Mobile Number Credit Limit
          </label>
        </div>
        <div className="input-with-icon">
          <input type="number" placeholder="5" readOnly />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <path
              fill="gray"
              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
            />
          </svg>
        </div>
      </div>
      <div className="credit-item">
        <div>
          <label className="input-label">Export Credit Limit</label>
        </div>
        <div className="input-with-icon">
          <input type="number" placeholder="10" readOnly />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
          >
            <path
              fill="gray"
              d="M6 22q-.825 0-1.412-.587T4 20V10q0-.825.588-1.412T6 8h1V6q0-2.075 1.463-3.537T12 1t3.538 1.463T17 6v2h1q.825 0 1.413.588T20 10v10q0 .825-.587 1.413T18 22zm0-2h12V10H6zm6-3q.825 0 1.413-.587T14 15t-.587-1.412T12 13t-1.412.588T10 15t.588 1.413T12 17M9 8h6V6q0-1.25-.875-2.125T12 3t-2.125.875T9 6zM6 20V10z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div style={{display:"none"}} className="mt-4">
      You must be on a Paying plan to reallocate credits.
      <Link>Click here to explore upgrade options</Link>
    </div>
  </div>
  );
}

export default Account_Info;
