import React from "react";
import ReactDOM from "react-dom/client";
import { useParams } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./index.css";
import App from "./App";

import About from "./About";

import Login from "./Auth/login";
import Register from "./Auth/register";
import Leads from "./leads";
import Users from "./users";
import Exports from "./exports";
import Subscription from "./subscription";
import Checkout from "./checkout";
import AdminPage from "./adminPage";
import Account_setting from "./Account_setting";
import Credit_page from "./credit";
import BillingPage from "./billing/billing_page";
import Processing from "./billing/processing";
import Payment_success from "./billing/payment_success";
import AccountSummary from "./billing/AccountSummary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        {/* <Route path="/About" element={<About />} /> */}
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/register" element={<Register />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/users" element={<Users />} />
        <Route path="/exports" element={<Exports />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/add_card" element={<Checkout />} />
        <Route path="/account_setting" element={<Account_setting />} />
        <Route path="/credits" element={<Credit_page />}/>
        <Route path="/billing" element={<BillingPage />} />
        <Route path="/processing" element={<Processing />} />
        <Route path="/payment_success" element={<Payment_success />} />
        {/* <Route path="/account_summary" element={<AccountSummary />} /> */}
      </Routes>
    </Router>
  </React.StrictMode>
);
