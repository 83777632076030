// ImportForm.js
import React, { useState } from 'react';
import LeadForm from './LeadForm';
import Modal from './modal';
import apiClient from './Auth/apiClient';

import { useNavigate } from 'react-router-dom';

const ExportForm = ({ onSubmit, filters, AllLeads }) => {
  const [file, setFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState('Fixed'); // State to track active tab
  const [exportstatus, setexportstatus] = useState(false);
  const [exportfail, setexportfail] = useState(false);
  const [noSubscription, setnoSubscription] = useState(false);
  const [noExportCredits, setnoExportCredits] = useState(false);
  const [recordCount, setRecordCount] = useState(''); // State for number of records
  const [perCount, setPerCount] = useState(''); // State for number of records

  const navigate = useNavigate();

  const handleExportcall = async () => {
    const DataToShow = recordCount || 10; // Default to 10 if no input is provided
    const perToShow = perCount || 0; // Default to 10 if no input is provided
  
    try {
      // Combine filters with additional parameters
      const params = { ...filters,per_page: recordCount, per_company: perToShow, lead_ids: AllLeads};
  
      // Construct the query string
      const queryString = new URLSearchParams(params).toString();
  
      // Make the API call
      const response = await apiClient.get(`/v1/leads/export-start?${queryString}`);

      if( response && response.data.status == "Failed" ) {
        if( response.data.message && response.data.message == "NO_SUBSCRIPTION" ) {
          setnoSubscription(true);
  
          // Hide the failure message after 5 seconds
          setTimeout(() => {
            setnoSubscription(false);
            // Now redirect user to Subscription page.....
            navigate('/subscription');
          }, 5000);
        } else if( response.data.message && response.data.message == "NO_EXPORT_CREDITS" ) {
          setnoExportCredits(true);
  
          // Hide the failure message after 5 seconds
          setTimeout(() => {
            setnoExportCredits(false);
            // Now redirect user to Buy More Credits page.....
            navigate('/subscription');
          }, 5000);
        }
      } else {
        setexportstatus(true);

        // Hide the status after 5 seconds
        setTimeout(() => {
          setexportstatus(false);
        }, 5000);
      }
  
      // Log the parameters for debugging
      // console.log('Filters:', filters, 'Per Company:', DataToShow, 'ID:', AllLeads);
      
    } catch (error) {
      setexportfail(true);
  
      // Hide the failure message after 5 seconds
      setTimeout(() => {
        setexportfail(false);
      }, 5000);
  
      console.error('Error exporting data:', error);
    }
  };
  

  const handleSelectChange = (event) => {
    setRecordCount(event.target.value);
  };

  const handleperChange = (event) => {
    setPerCount(event.target.value);
  }
  // Handle change for input (Custom tab)
  const handleInputChange = (event) => {
    setRecordCount(event.target.value);
  };
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <>
      {exportstatus && 
        <div className={`export-message export-success ${exportstatus ? 'show' : ''}`}>
          Data Exported Successfully
        </div>
      }
      {noSubscription && 
        <div className={`export-message export-fail ${noSubscription ? 'show' : ''}`}>
          No Subscription Found.
        </div>
      }
      {noExportCredits && 
        <div className={`export-message export-fail ${noExportCredits ? 'show' : ''}`}>
          No Export Credits Left.
        </div>
      }
       {exportfail && 
        <div className={`export-message export-fail ${exportfail ? 'show' : ''}`}>
          Data Not Exported
        </div>
      }
    <form >
            <div className="filter-tabs mb-3">
                <div className={`filter-tabs-content ${activeTab === 'Fixed' ? 'filter-tabs-content-active' : ''}`} onClick={() => handleTabClick('Fixed')}>Fixed</div>
                <div className={`filter-tabs-content ${activeTab === 'Custom' ? 'filter-tabs-content-active' : ''}`} onClick={() => handleTabClick('Custom')}>Custom</div>
            </div>
             
            <div className="form-group">
                {activeTab === 'Fixed' &&
                    <>
                        <label><b>Select Export Records</b></label>
                        <div className="file-input-wrapper mb-2">
                            <select class="form-select" name='record-select' onChange={handleSelectChange} aria-label="Default select example">
                                <option selected disabled>Select Number of Records</option>
                                <option value="all">All</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>

                        {/* <label><b>Select Export Per Company</b></label>
                        <div className="file-input-wrapper">
                            <select class="form-select" name='record-select' onChange={handleSelectChange} aria-label="Default select example">
                                <option selected disabled>Select Number of Records</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div> */}
                    </>
                }
                {activeTab === 'Custom' && 
                <>
                    <div className='form-group '>
                        <label><b>Enter Number of Records</b></label> 
                        <input class="form-control" type="number" name='record-entered' onChange={handleInputChange} placeholder="Enter Number or Records" aria-label="default input example"></input>
                    </div>
                     <label><b>Select Export Per Company</b></label>
                     <div className="file-input-wrapper">
                         <select class="form-select" name='record-select' onChange={handleperChange} aria-label="Default select example">
                             <option selected disabled>Select Number of Records</option>
                             <option value="10">10</option>
                             <option value="25">25</option>
                             <option value="50">50</option>
                             <option value="100">100</option>
                         </select>
                     </div>
                     </>
                }
            </div>
      <div className="form-buttons">
        <div className='import-btn' onClick={handleExportcall}>Export Leads</div>
      </div>
    </form>
    </>
  );
};

export default ExportForm;
