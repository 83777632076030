import React, { useState } from 'react';
import './InfoTooltip.css'; // Import the CSS file

const InfoTooltip = ({ message }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="info-tooltip"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="info-icon">?</span>
      {isHovered && <div className="tooltip-box">{message}</div>}
    </div>
  );
};

export default InfoTooltip;
