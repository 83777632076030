
import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./Auth/login";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from './assets/profile-image.png'


import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";


import "./App.css";


import { useEffect } from "react";
import apiClient from './Auth/apiClient'; // Import the custom axios instance



function App() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [exportsdata, setExportData] = useState([]);


  
  const handleDownload = async (id) => {
    try {
      // Send a request to get the file as a blob
      const response = await apiClient.get(`/v1/leads/export-download/${id}`, {
        responseType: 'blob', // Ensure the response is in blob format for files
      });
  
      // Create a URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
      const link = document.createElement('a');
      link.href = url;
  
      // Retrieve the file name from the response headers or use a default name with .csv extension
      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'Leads-file.csv'; // Default file name
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length === 2) {
          fileName = fileNameMatch[1];
        }
      }
  
      // Ensure the file name has a .csv extension
      if (!fileName.endsWith('.csv')) {
        fileName += '.csv';
      }
  
      // Set the download attribute with the file name
      link.setAttribute('download', fileName);
      
      // Append link to the body and trigger the click event
      document.body.appendChild(link);
      link.click();
      
      // Clean up the URL object and remove the link from the document
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the file:', error);
      // Optionally, handle the error with user notification
    }
  };
 

 
 
  const username = localStorage.getItem('username');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get('/v1/dashboard');
        const exports = await apiClient.get('/v1/leads/exports');

        setData(response.data);
        setExportData(exports.data.exportList);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login if unauthorized
          navigate('/login');
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getClassName = (title) => {
    switch (title) {
      case 'Finished':
        return 'completed-class';
      case 'Failed':
        return 'failed-class';
      case 'Pending':
        return 'pending-class';
    
    }
  };

  const formatDateTime = (dateTimeString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return new Date(dateTimeString).toLocaleDateString('en-US', options);
  };
  // if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

    
  return (data != null && (
    <div className="App" style={{overflow:'hidden'}}>
       <Sidebar />
  
       <div className='home-body'>
      <span className="greeting">
        {/* <b>Dashboard</b> */}
        
      <button className='export-btn'>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 20 20">
              <path fill="black" d="M15 15H2V6h2.595s.689-.896 2.17-2H1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h15a1 1 0 0 0 1-1v-3.746l-2 1.645zm-1.639-6.95v3.551L20 6.4l-6.639-4.999v3.131C5.3 4.532 5.3 12.5 5.3 12.5c2.282-3.748 3.686-4.45 8.061-4.45"/>
            </svg> Exports
          </button>
      </span>
      <div className='greeting'>
        <div>
        <h2 style={{margin:'10px 0 0 0'}}>Welcome Back, {username}</h2>
        <span>Track, Manage and Forecast your Customers</span>
        </div>
        <div>
          
        </div>
      </div>
      <div className='row box-section'>
        <div className='col-sm-4 main-box-content'>
          <div className='box-content'>
            <div>
              Total Leads
            </div> 
            <div className='icon'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
            <path fill="#ff7300" fill-rule="evenodd" d="M22 6.99V11a1 1 0 0 1-1.707.707L19 10.414l-5.293 5.293a1 1 0 0 1-1.414 0L9 12.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L13 13.586L17.586 9l-1.293-1.293A1 1 0 0 1 17 6h4l.048.001a.996.996 0 0 1 .952.99" clip-rule="evenodd"/></svg> </div>
          </div>
          <h2 className='col-value'>134M+</h2>
        </div>
        <div className='col-sm-4 main-box-content'>
          <div className='box-content'>
            <div>
              Total Exports
            </div> 
            <div className='icon-user'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 15 15"><path fill="green" d="M5.5 0a3.499 3.499 0 1 0 0 6.996A3.499 3.499 0 1 0 5.5 0m-2 8.994a3.5 3.5 0 0 0-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 0 0-3.5-3.5zm9 1.006H12v5h3v-2.5a2.5 2.5 0 0 0-2.5-2.5"/><path fill="green" d="M11.5 4a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5"/></svg>
            </div>
          </div>
          <h2 className='col-value'>{exportsdata.length}</h2>
        </div>
        <div className='col-sm-4 main-box-content'>
          <div className='box-content'>
            <div>
              Total Active
            </div> 
            <div className='icon-active'>
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 16 16">
	              <path fill="#ff3355" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z" />
              </svg>
            </div>
          </div>
          <h2 className='col-value'>2000</h2>
        </div>
      </div>
     </div>
    
     <section className="table-section-home">
      <div className="dashboard-users">
        <div className="dashboard-users-head">
          Recent Updates
        </div>
        <div className="dashboard-users-head1">
          <div className="dashboard-search">
                    <input type="text" placeholder="Search...." required />
                    <span className="dashboard-toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                            <path fill="#757575" d="M464 428L339.92 303.9a160.48 160.48 0 0 0 30.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0 0 94.58-30.72L428 464ZM209.32 319.69a110.38 110.38 0 1 1 110.37-110.37a110.5 110.5 0 0 1-110.37 110.37"/>
                        </svg>
                    </span>
            </div>
            <div className="dashboard-sort-filter">Sort By:Newest <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                        <g fill="none" fill-rule="evenodd">
                                                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                            <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                                        </g>
                                                                    </svg>
            </div>
        </div>
      </div>
            {/* <div className="row mb-2">
                <div className="col-sm-5 table-btn">
                    Recent Updates
                </div>
                <div className="col-sm-6 dashboard-btn-sort">
                <div className="dashboard-search">
                    <input type="text" placeholder="Search...." required />
                    <span className="dashboard-toggle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                            <path fill="#757575" d="M464 428L339.92 303.9a160.48 160.48 0 0 0 30.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0 0 94.58-30.72L428 464ZM209.32 319.69a110.38 110.38 0 1 1 110.37-110.37a110.5 110.5 0 0 1-110.37 110.37"/>
                        </svg>
                    </span>
                </div>


                    <div className="dashboard-sort-filter">Sort By:Newest <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                                                        <g fill="none" fill-rule="evenodd">
                                                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                                                            <path fill="black" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z" />
                                                                        </g>
                                                                    </svg>
                    </div>
                   
                </div>
            </div> */}
            <div className="table-responsive">
            <table className="table">
                <thead>
                <tr>
                    <th className="table-heading">ID</th>
                    <th className="table-heading">Exported Date</th>
                    <th className="table-heading">Status</th>
                    <th className="table-heading">Download</th>
                   
                </tr>
                </thead>
                <tbody>
                    {exportsdata
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Step 1: Sort by created_at in descending order
                        .slice(0, 8) // Step 2: Take the first 5 elements (latest entries)
                        .map((exports) => ( // Step 3: Map through the sliced array
                            <tr key={exports.id}>
                                <td className="table-body">{exports.id}</td>
                                <td className="table-body">{formatDateTime(exports.created_at)}</td>
                                <td className="table-body">                      
                                        <div className={`${getClassName(exports.status)}`}>{exports.status}</div>
                                </td>
                                <td className="table-body">
                                  <a href='#' onClick={() => handleDownload(exports.id)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                            <path fill="orange" d="M11.5 4a4.5 4.5 0 0 1 4.344 3.32c.104.383.421.67.811.733A4.001 4.001 0 0 1 18 15.466a1 1 0 1 0 1.002 1.73a6.002 6.002 0 0 0-1.415-10.985A6.502 6.502 0 0 0 5 8.417a5 5 0 0 0 .124 9.22a1 1 0 0 0 .75-1.854a3.001 3.001 0 0 1 .394-5.692A1.01 1.01 0 0 0 7.027 9C6.74 6.38 8.851 4 11.5 4m.5 7a1 1 0 0 1 1 1v6.584l1.293-1.292a1 1 0 0 1 1.414 1.416l-2.824 2.819c-.253.252-.5.473-.883.473c-.384 0-.63-.22-.883-.473l-2.824-2.82a1 1 0 0 1 1.414-1.415L11 18.584V12a1 1 0 0 1 1-1" />
                                        </g>
                                    </svg>
                                  </a>
                                </td>
                            </tr>
                        ))}
                </tbody>


            </table>
            </div>


        </section>
     
      
    </div>
  )
);
}

export default App;

