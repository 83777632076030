import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import axios from "axios";
import apiClient from './Auth/apiClient';
import { useNavigate } from 'react-router-dom';


// Initialize Stripe
const stripePromise = loadStripe('pk_test_51I9ms3BVCRZ4SwrNP7HSm8P8g15f77ZZxYBdeTSbp6KXrgTiMgbp8r3W4PAxtyVPeDrpIUqux1dU4deI6Xx8jDDP00qvk4903N');

// Custom Styles for Card Elements
const elementOptions = {
  style: {
    base: {
      fontSize: '16px',
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

// Styled Components
const StyledForm = styled.form`
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

const StyledLabel = styled.label`
  font-size: 14px;
  margin-bottom: 5px;
`;

const StyledButton = styled.button`
  width: 100%;
  padding: 12px;
  background:linear-gradient(90deg, #4a00e0, #8e2de2);  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
  background:linear-gradient(90deg, #8e2de2, #4a00e0);  
  }
`;

const AddCardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setIsProcessing(true);

    const cardNumberElement = elements.getElement(CardNumberElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
    });

    if (error) {
      console.error(error);
    } else {
      console.log('Payment Method Created:', paymentMethod);
      const paymentMethodId = paymentMethod.id;
      // console.log(paymentMethodId);
      try {
        const response = await apiClient.post('/v1/payment-methods', {
          payment_method_id: paymentMethodId,
        });
  
        console.log('Payment Method Added:', response.data);
        // Redirect to settings page
        navigate('/billing');
      } catch (apiError) {
        console.error('API Error:', apiError);
      } 
    }

    setIsProcessing(false);
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <FormRow>
        <StyledLabel>Card Number</StyledLabel>
        <CardNumberElement options={elementOptions} />
      </FormRow>
      <FormRow>
        <StyledLabel>Expiry Date</StyledLabel>
        <CardExpiryElement options={elementOptions} />
      </FormRow>
      <FormRow>
        <StyledLabel>CVC</StyledLabel>
        <CardCvcElement options={elementOptions} />
      </FormRow>
      <StyledButton type="submit" disabled={!stripe || isProcessing}>
        {isProcessing ? 'Processing...' : 'Add Card'}
      </StyledButton>
    </StyledForm>
  );
};

const Cards = () => (
  <Elements stripe={stripePromise}>
    <AddCardForm />
  </Elements>
);

export default Cards;
