
import feather from "feather-icons";
import Sidebar from "../Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from '../assets/profile-image.png'

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";


import "../App.css";
import "./Billing.css";

import { useEffect } from "react";
import Modal from "../modal";
import ImportForm from "../ImportForm";
import LeadForm from "../LeadForm";

import apiClient from '../Auth/apiClient'; // Import the custom axios instance



function Payment_success() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Leads'); // State to track active tab
  const [activeBtn, setactiveBtn] = useState(null); // State to track active tab
  // Function to handle click on a list item
  const [isAnnual, setIsAnnual] = useState(true); // State to manage annual/monthly toggle

   

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModallead, setShowModallead] = useState(false);
  const [data, setData] = useState(null);
  const [carddata, setCardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModallead = () => setShowModallead(true);
  const handleCloseModallead = () => setShowModallead(false);

  const handleFilterSidebar = () => {
      setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const closeSidebar = () => {
    setIsFilterSidebarOpen(false);
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get('/v1/my-subscription');
        const response_card = await apiClient.get('/v1/payment-methods');
        setData(response.data);
        setCardData(response_card.data)
        // console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login if unauthorized
          navigate('/login');
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
};

  return (
    <div className="App" style={{overflow:'hidden'}}>
       <Sidebar />
     
    
       <div className='home-body'>
       <div className="success_loader"><svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 24 24">
	<path fill="green" d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4l8-8z" />
</svg></div>
            <div className="success_head">Payment Success</div>
            <div className="success_body">Please wait a moment we will redirect you to the confirmation page</div>
            <div className="success_loader"><svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24">
	<g>
		<rect width="2" height="5" x="11" y="1" fill="green" opacity="0.14" />
		<rect width="2" height="5" x="11" y="1" fill="green" opacity="0.29" transform="rotate(30 12 12)" />
		<rect width="2" height="5" x="11" y="1" fill="green" opacity="0.43" transform="rotate(60 12 12)" />
		<rect width="2" height="5" x="11" y="1" fill="green" opacity="0.57" transform="rotate(90 12 12)" />
		<rect width="2" height="5" x="11" y="1" fill="green" opacity="0.71" transform="rotate(120 12 12)" />
		<rect width="2" height="5" x="11" y="1" fill="green" opacity="0.86" transform="rotate(150 12 12)" />
		<rect width="2" height="5" x="11" y="1" fill="green" transform="rotate(180 12 12)" />
		<animateTransform attributeName="transform" calcMode="discrete" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12" />
	</g>
</svg></div>
    </div>
 
     
      
    </div>
  );
}

export default Payment_success;

 