
import feather from "feather-icons";
import Sidebar from "../Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from '../assets/profile-image.png'

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";


import "../App.css";
import "./Billing.css";

import { useEffect } from "react";
import Modal from "../modal";
import ImportForm from "../ImportForm";
import LeadForm from "../LeadForm";

import apiClient from '../Auth/apiClient'; // Import the custom axios instance



function Processing() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Leads'); // State to track active tab
  const [activeBtn, setactiveBtn] = useState(null); // State to track active tab
  // Function to handle click on a list item
  const [isAnnual, setIsAnnual] = useState(true); // State to manage annual/monthly toggle

   

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModallead, setShowModallead] = useState(false);
  const [data, setData] = useState(null);
  const [carddata, setCardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModallead = () => setShowModallead(true);
  const handleCloseModallead = () => setShowModallead(false);

  const handleFilterSidebar = () => {
      setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const closeSidebar = () => {
    setIsFilterSidebarOpen(false);
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get('/v1/my-subscription');
        const response_card = await apiClient.get('/v1/payment-methods');
        setData(response.data);
        setCardData(response_card.data)
        // console.log(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login if unauthorized
          navigate('/login');
        } else {
          console.error('Error fetching data:', error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
};

  return (
    <div className="App" style={{overflow:'hidden'}}>
       <Sidebar />
     
    
       <div className='home-body'>
            <div className="process_head">Order Processing</div>
            <div className="process_body">Please do not click back button</div>
            <div className="process_loader"><svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="60"
                                height="60"
                                viewBox="0 0 24 24"
                              >
                                <circle cx="12" cy="3" r="0" fill="#000">
                                  <animate
                                    id="svgSpinners6DotsScale0"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="0;svgSpinners6DotsScale2.end-0.5s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="16.5"
                                  cy="4.21"
                                  r="0"
                                  fill="#000"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale1"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale0.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="7.5" cy="4.21" r="0" fill="#000">
                                  <animate
                                    id="svgSpinners6DotsScale2"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale4.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="19.79"
                                  cy="7.5"
                                  r="0"
                                  fill="#000"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale3"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale1.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="4.21" cy="7.5" r="0" fill="#000">
                                  <animate
                                    id="svgSpinners6DotsScale4"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale6.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="21" cy="12" r="0" fill="#000">
                                  <animate
                                    id="svgSpinners6DotsScale5"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale3.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="3" cy="12" r="0" fill="#000">
                                  <animate
                                    id="svgSpinners6DotsScale6"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale8.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="19.79"
                                  cy="16.5"
                                  r="0"
                                  fill="#000"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale7"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale5.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="4.21"
                                  cy="16.5"
                                  r="0"
                                  fill="#000"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale8"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScalea.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="16.5"
                                  cy="19.79"
                                  r="0"
                                  fill="#000"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale9"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale7.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="7.5"
                                  cy="19.79"
                                  r="0"
                                  fill="#000"
                                >
                                  <animate
                                    id="svgSpinners6DotsScalea"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScaleb.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="12" cy="21" r="0" fill="#000">
                                  <animate
                                    id="svgSpinners6DotsScaleb"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale9.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                              </svg></div>
    </div>
 
     
      
    </div>
  );
}

export default Processing;

 