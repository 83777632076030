import React, { useState } from 'react';

import "./AdminPage.css";
import UserList from './userList';

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState('general');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'general':
        return <GeneralSettings />;
      case 'user':
        return <UserSettings />;
      case 'security':
        return <SecuritySettings />;
      default:
        return <GeneralSettings />;
    }
  };

  return (
    <div className="admin-page">
      <h1>Admin Page</h1>
      <div className="tabs">
        <button onClick={() => setActiveTab('general')} className={activeTab === 'general' ? 'active' : ''}>
          General
        </button>
        <button onClick={() => setActiveTab('user')} className={activeTab === 'user' ? 'active' : ''}>
          User Management
        </button>
        <button onClick={() => setActiveTab('security')} className={activeTab === 'security' ? 'active' : ''}>
          Security
        </button>
      </div>
      <div className="tab-content">
        {renderTabContent()}
      </div>
    </div>
  );
};

const GeneralSettings = () => (
  <div>
    <h2>General Settings</h2>
  </div>
);

const UserSettings = () => (
  <div>
    <UserList />
  </div>
);

const SecuritySettings = () => (
  <div>
    <h2>Security Settings</h2>
  </div>
);

export default AdminPage;
