
import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./Auth/login";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import profile from './assets/profile-image.png'




import "./App.css";
import "./leads.css";
import "./Payment.css";


import { useEffect } from "react";
import Modal from "./modal";
import ImportForm from "./ImportForm";
import LeadForm from "./LeadForm";
import Cards from "./cards";




function Checkout() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('Leads'); // State to track active tab
  const [activeBtn, setactiveBtn] = useState(null); // State to track active tab
  // Function to handle click on a list item
  const [isAnnual, setIsAnnual] = useState(true); // State to manage annual/monthly toggle

   

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModallead, setShowModallead] = useState(false);
  
  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModallead = () => setShowModallead(true);
  const handleCloseModallead = () => setShowModallead(false);

  const handleFilterSidebar = () => {
      setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const closeSidebar = () => {
    setIsFilterSidebarOpen(false);
  }

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
};

  return (
    <div className="App" style={{overflow:'hidden',backgroundColor:'#f1f5fb'}}>
       <Sidebar />
     
    
       <div className='home-body'>
      {/* <span >
      Dashboard > <b>Payment</b>
      </span> */}
      <Cards />
       {/* <section className="payment-page">
            
            <h1 className="pricing-head">Enter Payment Information</h1>
            <span className="payment-tagline">One more step before closing more revenue with Revenuewave.</span>
            <div className="payment-form">
                <div className="bill-address">Billing Address</div>
                <div className="form-group">
        
        <div className='row p-2'>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>First Name</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter First Name' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Last Name</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Last Name' id="fileInput" />
                </div>
            </div>
            <div className='col-sm-12 mb-2 font-change1'>
                <label className='mb-2'><b>Address</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Address' id="fileInput" />
                </div>
            </div>
            <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>City</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter City' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>State</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter State' id="fileInput" />
                </div>
            </div>
            <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Country</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Country' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-4 font-change1'>
                <label className='mb-2'><b>Postal Code</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter Postal Code' id="fileInput" />
                </div>
            </div>

            <div className="bill-address mb-4">Payment Method</div>

            <div className='col-sm-12 mb-2 font-change1'>
                <label className='mb-2'><b>Credit Card Number</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='1234 1234 1234 1234' id="fileInput" />
                </div>
            </div>
            <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>Card exiration date</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='MM / YY' id="fileInput" />
                </div>
            </div>
             <div className='col-sm-6 mb-2 font-change1'>
                <label className='mb-2'><b>CVV</b></label>
                <div className="form-input">
                    <input className='form-control font-change' type="text" placeholder='Enter CVV' id="fileInput" />
                </div>
            </div>

            <div className="plan-btn ">Add Credit Card</div>
        </div>
       
        </div>
            </div>
        </section>*/}
    </div>
 
     
      
    </div>
  );
}

export default Checkout;

 