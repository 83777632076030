import feather from "feather-icons";
import Sidebar from "../Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import profile from "../assets/profile-image.png";

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import "../App.css";
import "./Billing.css";

import { useEffect } from "react";
import Modal from "../modal";
import ImportForm from "../ImportForm";
import LeadForm from "../LeadForm";

import apiClient from "../Auth/apiClient"; // Import the custom axios instance

// import { useNavigate } from 'react-router-dom';

function BillingPage() {
  // const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Leads"); // State to track active tab
  const [activeBtn, setactiveBtn] = useState(null); // State to track active tab
  // Function to handle click on a list item
  const [isAnnual, setIsAnnual] = useState(true); // State to manage annual/monthly toggle

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModallead, setShowModallead] = useState(false);
  const [data, setData] = useState(null);
  const [carddata, setCardData] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModallead = () => setShowModallead(true);
  const handleCloseModallead = () => setShowModallead(false);

  const [NotificationMessage, setNotificationMessage] = useState("");
  const [SuccessNotification, setSuccessNotification] = useState(0);

  const navigate = useNavigate();

  const handleFilterSidebar = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const closeSidebar = () => {
    setIsFilterSidebarOpen(false);
  };

  const fetchData = async () => {
    try {
      const response = await apiClient.get("/v1/my-subscription");
      // const response_card = await apiClient.get("/v1/payment-methods");
      setData(response.data);
      // setCardData(response_card.data);
      console.log(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login if unauthorized
        navigate("/login");
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const fetchMyPaymentMethods = async () => {
    try {
      // const response = await apiClient.get("/v1/my-subscription");
      const response_card = await apiClient.get("/v1/payment-methods");
      // setData(response.data);
      setCardData(response_card.data);
      // console.log(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login if unauthorized
        navigate("/login");
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchMyPaymentMethods();
  }, []);

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 1);
  
    // return date.toLocaleDateString('en-US', options);
    return date.toLocaleDateString('en-US', options);
  }

  const handleDelete = async (id) => {
    try {
      const response = await apiClient.delete(`/v1/payment-methods/${id}`);
      console.log("API Response:", response.data);
      // Handle the response as needed
      // navigate('/billing');
      // fetchData();
      fetchMyPaymentMethods();
    } catch (error) {
      console.error("Error fetching payment method:", error.message);
      console.error("Error details:", error.config);
    }
  };

  const handleInvoiceDownload = async ()  => {
    try {
      const response = await apiClient.get(`/v1/invoices/latest`);
      console.log("API Response:", response.data);
      // Handle the response as needed
      var status = response.data.status;
      if( status && status == "success" ) {
        window.open( response.data.data.invoice_url , '_blank', 'noopener,noreferrer')
        // if (newWindow) newWindow.opener = null
      }
    } catch (error) {
      console.error("Error fetching payment method:", error.message);
      console.error("Error details:", error.config);
    }
  };

  const handleSetDefaultCard = async (method_id) => {
    try {
      const response = await apiClient.get(`/v1/payment-methods/${method_id}/default`);
      console.log("API Response:", response.data);
      // Handle the response as needed
      var status = response.data.success;
      if( status == true ) {
        setNotificationMessage("Default Payment Method Updated...");
        setSuccessNotification(1);

        // Hide the failure message after 5 seconds
        setTimeout(() => {
          setSuccessNotification(0);
        }, 5000);

      }
      // fetchData();
      fetchMyPaymentMethods();
    } catch (error) {
      console.error("Error fetching payment method:", error.message);
      console.error("Error details:", error.config);
    }
  };

  const handleCanclePlan = async () => {
    setNotificationMessage("Contact Admin");
    setSuccessNotification(-1);

    // Hide the failure message after 5 seconds
    setTimeout(() => {
      setSuccessNotification(0);
    }, 5000);
  };
  

  const handleToggle = () => {
    setIsAnnual(!isAnnual);
  };

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Sidebar />

      {SuccessNotification ? (
        <div className={`export-message export-success ${SuccessNotification ? ( SuccessNotification == 1 ? 'export-success show' : 'export-fail show') : ''}`}>
          {NotificationMessage}
        </div>
      )
      : (<></>)
      }

      <div className="home-body">
        <div className="d-flex justify-content-between">
          <div>
            Dashboard &gt; <b>Billing</b>
          </div>
          <div>
            <button className="download_invoice" onClick={handleInvoiceDownload}>Download Invoice</button>
          </div>
        </div>
        <div className="billing-container">
          <div className="current-plan-section">
            <div className="card1-header">
              <h3>Current Plan</h3>
            </div>
            <div className="plan-details">
              <div className="plan-type">
                <h4 className="plan-width">Plan</h4>
                <p>
                  {data && data.data && data.data.price.recurring.interval}ly
                </p>
              </div>
              <div className="plan-benefits">
                <h4 className="plan-width">Plan Benefits</h4>
                <ul>
                  <li>{data && data.data && data.data.product.description}</li>
                  {/* <li>Fully qualified and wildcard domains</li>
                            <li>Cloud discovery scanning</li>
                            <li>Automated certificate management (ACME)</li>
                            <li>Manual certificate enrollment</li>
                            <li>Unlimited 90 day public SSL certificates</li>
                            <li>Unlimited 1 year public SSL certificates</li> */}
                </ul>
              </div>
              <button className="cancel-plan-button" onClick={handleCanclePlan}>Cancel Your Plan</button>
            </div>
          </div>

          <div className="upcoming-renewal-section">
            <div className="card1-header">
              <h3>Your Upcoming Renewal</h3>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Qty</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data && data.data && data.data.product.name}</td>
                  <td>1</td>
                  <td>
                    ${data && data.data && data.data.price.unit_amount / 100}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Monthly Total</strong>
                  </td>
                  <td></td>
                  <td>
                    <strong>
                      ${data && data.data && data.data.price.unit_amount / 100}
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Next bill date: <strong>{data && data.data && ( formatDate(data.data.subscription.created_at))}</strong>
            </p>
          </div>

          <div className="payment-method-section">
            <div className="card1-header">
              <h3>Payment Method</h3>
              <Link to="/add_card">
                {" "}
                <button className="download_invoice" style={{margin:"0px 0px 5px 0px"}}>Add Card</button>
              </Link>
            </div>
            {carddata &&
            carddata.data &&
            carddata.data.payment_methods.map((method) => (
              <div className="payment-method" key={method.id}>
                <div className="d-flex">
                  <label>
                    <input
                      type="radio"
                      name="payment-method"
                      style={{margin:"5px"}}
                      value={method.id}
                      checked={carddata.data.default_payment_method === method.id}
                      onChange={() => handleSetDefaultCard(method.id)}
                    />
                    <span>{method.card.display_brand}</span>
                  </label>
                </div>
                <div>
                  <span>XXXX-XXXX-XXXX-{method.card.last4}</span>
                  {carddata.data.default_payment_method !== method.id ? (
                    <span
                      style={{ marginLeft: "10px", cursor: 'pointer' }}
                      onClick={() => handleDelete(method.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="red"
                          d="M7 21q-.825 0-1.412-.587T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.587 1.413T17 21zm2-4h2V8H9zm4 0h2V8h-2z"
                        />
                      </svg>
                    </span>
                  )
                :(
                  <span style={{marginLeft:"10px"}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="green"
                        d="M20.285 5.715c-.291-.291-.767-.291-1.058 0L9 15.415 5.773 12.188a.75.75 0 0 0-1.061 1.061l4.5 4.5a.75.75 0 0 0 1.061 0l9.5-9.5a.75.75 0 0 0 0-1.061z"
                      />
                    </svg>
                  </span>
                )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingPage;
