import feather from "feather-icons";
import Sidebar from "../Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "../Auth/login";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import profile from "../assets/profile-image.png";

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import "../Account.css";

import { useEffect } from "react";
import apiClient from "../Auth/apiClient"; // Import the custom axios instance


function Customer() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [exportsdata, setExportData] = useState([]);

  const username = localStorage.getItem("username");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiClient.get("/v1/dashboard");
        const exports = await apiClient.get("/v1/leads/exports");

        setData(response.data);
        setExportData(exports.data.exportList);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login if unauthorized
          navigate("/login");
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="custom-user-field">
            <div className="d-flex justify-content-between head-card mb-4">
              <div>
                <h2 className="">Custom User Field</h2>
              </div>
              <div>
                <Link>Manage Fields</Link>{" "}
              </div>
            </div>
            <p>
              Your team does not have custom user fields set up yet. You can set
              them up <Link>here</Link>.
            </p>
          </div>
  );
}

export default Customer;
