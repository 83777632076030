import React, { useEffect, useState } from "react";
import feather from "feather-icons";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

import "./App.css";
import "./sidebar.css";
import apiClient from './Auth/apiClient'; // Import the custom axios instance

import profile from './assets/profile-image.png'


function Sidebar() {

  const navigate = useNavigate();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Overview'); // State to track active tab
  const [userdata ,setUserData] = useState(null);
  const [loading ,setLoading] = useState(true);
  const location = useLocation();
  const [isTeamOpen, setIsTeamOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const toggleTeam = () => {
    setIsTeamOpen(!isTeamOpen);
    setActiveTab('Billing');
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await apiClient.get('/v1/users');
  //       // Assuming the leads data is under the "data" key in the response
  //       setUserData(response.data);
  //       console.log(response.data);
  //       setLoading(false);
  //     } catch (error) {
  //       if (error.response && error.response.status === 401) {
  //         // Redirect to login if unauthorized
  //         navigate('/login');
  //       } else {
  //         console.error('Error fetching data:', error);
  //       }
  //       console.error('Error fetching leads:', error);
  //       setLoading(false);
  //     }
  //   }
     
  //   fetchData();
  // }, []);

  const username = localStorage.getItem('username');
  const user_info = JSON.parse(localStorage.getItem('user_info'));
  var is_admin = false;
  if( user_info ) {
    is_admin = user_info.user.is_admin == true ? true : false;
  } 


  const handleLogout = async () => {
    try {
      // Perform the logout API call
      await apiClient.post('/v1/logout');
      
      // Navigate to the login page after successful logout
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
      
      // Handle the 401 error or any other error as needed
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
    }
  };

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case '/leads':
        setActiveTab('Leads');
        break;
      case '/admin':
        setActiveTab('AdminPage');
        break;
      case '/billing':
          setActiveTab('Billing');
          break;
      case '/account_setting':
          setActiveTab('AccountSettings');
          break;
      case '/credits':
          setActiveTab('credits');
          break;
      case '/users':
        setActiveTab('Users');
        break;
      case '/exports':
        setActiveTab('Exports');
        break;
      default:
        setActiveTab('Overview');
    }
  }, [location.pathname]);

  return (
    <div>
      <div
        className={`offcanvas sidebar-offcanvas offcanvas-start${
          isSidebarOpen ? " show" : ""
        }`}
        tabIndex={-1}
        id="offcanvasLeft"
      >
        <div className="offcanvas-header sidebar-header">
          <a onClick={closeSidebar}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	            <path fill="#000" d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
            </svg>
          </a>

          <h2 className="navbar-header">REVENUEWAVE</h2>

         
        </div>
        <div className="offcanvas-body">
          <div className="sidebar-content " style={{height:"100%"}}>
            <ul className="link-section " style={{height:"inherit"}}>
              <li className="sidebar-contain" >
                <Link to="/" className={`pages sidebar-contain ${activeTab === 'Overview' ? 'active' : ''}`} onClick={() => setActiveTab('Overview')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Overview' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><g fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z"/>
                        <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21"/></g></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24"><g fill="none" stroke="#5800ac" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"><path d="M6.133 21C4.955 21 4 20.02 4 18.81v-8.802c0-.665.295-1.295.8-1.71l5.867-4.818a2.09 2.09 0 0 1 2.666 0l5.866 4.818c.506.415.801 1.045.801 1.71v8.802c0 1.21-.955 2.19-2.133 2.19z"/>
                        <path d="M9.5 21v-5.5a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2V21"/></g></svg>}
                    </div> 
                    Overview
                </Link>
              </li>
              { is_admin &&
              <li className="sidebar-contain">
                <Link to="/admin" className={`pages sidebar-contain ${activeTab === 'AdminPage' ? 'active' : ''}`} onClick={() => setActiveTab('AdminPage')}>
                <div className='sidebar-icon'>
                        {activeTab === 'AdminPage' ? <svg style={{fill:"rgb(255,255,255)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="m17,15c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.449-1-1s.449-1,1-1,1,.449,1,1-.449,1-1,1Zm-13-4h4v1h-4c-1.654,0-3,1.346-3,3v6H0v-6c0-2.206,1.794-4,4-4ZM14,6c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm14,6c0-.353-.046-.716-.14-1.101l1.941-1.133-1.513-2.591-1.936,1.13c-.537-.488-1.167-.851-1.853-1.068v-2.238h-3v2.238c-.687.217-1.316.58-1.853,1.068l-1.936-1.13-1.513,2.591,1.941,1.133c-.094.385-.14.748-.14,1.101s.046.715.14,1.1l-1.941,1.134,1.513,2.591,1.936-1.131c.537.489,1.167.852,1.853,1.068v2.238h3v-2.238c.687-.217,1.316-.58,1.853-1.068l1.936,1.131,1.513-2.591-1.941-1.134c.094-.385.14-.747.14-1.1Zm.434,2.593l-.504.863-1.747-1.02-.272.291c-.549.586-1.249.989-2.021,1.165l-.389.088v2.019h-1v-2.019l-.389-.088c-.773-.176-1.472-.579-2.021-1.165l-.272-.291-1.747,1.02-.504-.863,1.755-1.025-.119-.38c-.138-.439-.202-.817-.202-1.188s.064-.748.202-1.188l.119-.38-1.755-1.024.504-.863,1.747,1.02.273-.291c.549-.586,1.249-.989,2.021-1.165l.389-.088v-2.019h1v2.019l.389.088c.773.176,1.472.579,2.021,1.165l.273.291,1.747-1.02.504.863-1.755,1.024.119.38c.138.44.202.818.202,1.188s-.064.748-.202,1.188l-.119.38,1.755,1.025Z"/></svg> 
                        : <svg style={{fill:"rgb(88,0,172)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="m17,15c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.449-1-1s.449-1,1-1,1,.449,1,1-.449,1-1,1Zm-13-4h4v1h-4c-1.654,0-3,1.346-3,3v6H0v-6c0-2.206,1.794-4,4-4ZM14,6c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm14,6c0-.353-.046-.716-.14-1.101l1.941-1.133-1.513-2.591-1.936,1.13c-.537-.488-1.167-.851-1.853-1.068v-2.238h-3v2.238c-.687.217-1.316.58-1.853,1.068l-1.936-1.13-1.513,2.591,1.941,1.133c-.094.385-.14.748-.14,1.101s.046.715.14,1.1l-1.941,1.134,1.513,2.591,1.936-1.131c.537.489,1.167.852,1.853,1.068v2.238h3v-2.238c.687-.217,1.316-.58,1.853-1.068l1.936,1.131,1.513-2.591-1.941-1.134c.094-.385.14-.747.14-1.1Zm.434,2.593l-.504.863-1.747-1.02-.272.291c-.549.586-1.249.989-2.021,1.165l-.389.088v2.019h-1v-2.019l-.389-.088c-.773-.176-1.472-.579-2.021-1.165l-.272-.291-1.747,1.02-.504-.863,1.755-1.025-.119-.38c-.138-.439-.202-.817-.202-1.188s.064-.748.202-1.188l.119-.38-1.755-1.024.504-.863,1.747,1.02.273-.291c.549-.586,1.249-.989,2.021-1.165l.389-.088v-2.019h1v2.019l.389.088c.773.176,1.472.579,2.021,1.165l.273.291,1.747-1.02.504.863-1.755,1.024.119.38c.138.44.202.818.202,1.188s-.064.748-.202,1.188l-.119.38,1.755,1.025Z"/></svg>
                      }
                    </div> 
                    Admin Settings
                </Link>
              </li>
              }
              <li className="sidebar-contain">
                <Link to="/leads" className={`pages sidebar-contain ${activeTab === 'Leads' ? 'active' : ''}`} onClick={() => setActiveTab('Leads')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Leads' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                        <path fill="white" fill-rule="evenodd" d="M22 6.99V11a1 1 0 0 1-1.707.707L19 10.414l-5.293 5.293a1 1 0 0 1-1.414 0L9 12.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L13 13.586L17.586 9l-1.293-1.293A1 1 0 0 1 17 6h4l.048.001a.996.996 0 0 1 .952.99" clip-rule="evenodd"/></svg> :<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
                        <path fill="#5800ac" fill-rule="evenodd" d="M22 6.99V11a1 1 0 0 1-1.707.707L19 10.414l-5.293 5.293a1 1 0 0 1-1.414 0L9 12.414l-5.293 5.293a1 1 0 0 1-1.414-1.414l6-6a1 1 0 0 1 1.414 0L13 13.586L17.586 9l-1.293-1.293A1 1 0 0 1 17 6h4l.048.001a.996.996 0 0 1 .952.99" clip-rule="evenodd"/></svg>}
                    </div> 
                    Leads
                </Link>
              </li>
              {/* <li className="sidebar-contain">
                <Link to="/users" className={`pages sidebar-contain ${activeTab === 'Users' ? 'active' : ''}`} onClick={() => setActiveTab('Users')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Users' ? <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15">
                        <path fill="white" d="M5.5 0a3.499 3.499 0 1 0 0 6.996A3.499 3.499 0 1 0 5.5 0m-2 8.994a3.5 3.5 0 0 0-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 0 0-3.5-3.5zm9 1.006H12v5h3v-2.5a2.5 2.5 0 0 0-2.5-2.5"/>
                        <path fill="white" d="M11.5 4a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5"/></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15 15">
                        <path fill="#5800ac" d="M5.5 0a3.499 3.499 0 1 0 0 6.996A3.499 3.499 0 1 0 5.5 0m-2 8.994a3.5 3.5 0 0 0-3.5 3.5v2.497h11v-2.497a3.5 3.5 0 0 0-3.5-3.5zm9 1.006H12v5h3v-2.5a2.5 2.5 0 0 0-2.5-2.5"/>
                        <path fill="#5800ac" d="M11.5 4a2.5 2.5 0 1 0 0 5a2.5 2.5 0 0 0 0-5"/></svg>}
                    </div> 
                    Users
                </Link>
              </li> */}
              <li>
                <div className="team-section">
                <div  style={{color:'rgb(88,0,172)'}} className={`pages sidebar-contain ${activeTab === 'Billing' ? 'active' : ''}`} onClick={toggleTeam}>
                <div className='sidebar-icon'>
                        {activeTab === 'Billing' ? <svg style={{fill:"rgb(255,255,255)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="M24,20c0,1.654-1.346,3-3,3v1h-2v-1c-1.654,0-3-1.346-3-3h2c0,.551,.448,1,1,1h2c.552,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.041-.507c-1.342-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-1h2v1c1.654,0,3,1.346,3,3h-2c0-.551-.448-1-1-1h-2c-.552,0-1,.449-1,1,0,.378,.271,.698,.644,.76l3.041,.507c1.342,.223,2.315,1.373,2.315,2.733Zm-9.899-5c.152-.743,.482-1.416,.924-2H5v7H14v-2H7v-3h7.101ZM5,11h5v-2H5v2Zm5-6H5v2h5v-2Zm6.031,19H1V3C1,1.346,2.346,0,4,0H13.414l7.586,7.586v2.414h-2v-1h-7V2H4c-.551,0-1,.449-1,1V22H14.424c.352,.801,.913,1.483,1.607,2ZM14,7h3.586l-3.586-3.586v3.586Z"/>
                        </svg> : <svg style={{fill:"rgb(88,0,172)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="M24,20c0,1.654-1.346,3-3,3v1h-2v-1c-1.654,0-3-1.346-3-3h2c0,.551,.448,1,1,1h2c.552,0,1-.449,1-1,0-.378-.271-.698-.644-.76l-3.041-.507c-1.342-.223-2.315-1.373-2.315-2.733,0-1.654,1.346-3,3-3v-1h2v1c1.654,0,3,1.346,3,3h-2c0-.551-.448-1-1-1h-2c-.552,0-1,.449-1,1,0,.378,.271,.698,.644,.76l3.041,.507c1.342,.223,2.315,1.373,2.315,2.733Zm-9.899-5c.152-.743,.482-1.416,.924-2H5v7H14v-2H7v-3h7.101ZM5,11h5v-2H5v2Zm5-6H5v2h5v-2Zm6.031,19H1V3C1,1.346,2.346,0,4,0H13.414l7.586,7.586v2.414h-2v-1h-7V2H4c-.551,0-1,.449-1,1V22H14.424c.352,.801,.913,1.483,1.607,2ZM14,7h3.586l-3.586-3.586v3.586Z"/></svg>
                      }
                    </div> 
                    Billing
                </div>
                  {isTeamOpen && (
                    <ul className="sub-menu">
                      <li>
                        <Link to="/subscription" className="pages">
                         <h3 style={{color:'rgb(88,0,172)'}}>Subscription</h3>
                        </Link>
                      </li>
                      <hr/>
                      {/* <li>
                        <Link to="/#" className="pages">
                          <h3 style={{color:'rgb(88,0,172)'}}>Payment History</h3>
                        </Link>
                      </li>
                      <hr/> */}
                      <li>
                        <Link to="/billing" className="pages">
                          <h3 style={{color:'rgb(88,0,172)'}}>Payment Method</h3>
                        </Link>
                      </li>
                    </ul>
                  )}
                </div>
              </li>
             
              <li className="sidebar-contain">
                <Link to="/account_setting" className={`pages sidebar-contain ${activeTab === 'AccountSettings' ? 'active' : ''}`} onClick={() => setActiveTab('AccountSettings')}>
                <div className='sidebar-icon'>
                        {activeTab === 'AccountSettings' ? <svg style={{fill:"rgb(255,255,255)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="m17,15c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.449-1-1s.449-1,1-1,1,.449,1,1-.449,1-1,1Zm-13-4h4v1h-4c-1.654,0-3,1.346-3,3v6H0v-6c0-2.206,1.794-4,4-4ZM14,6c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm14,6c0-.353-.046-.716-.14-1.101l1.941-1.133-1.513-2.591-1.936,1.13c-.537-.488-1.167-.851-1.853-1.068v-2.238h-3v2.238c-.687.217-1.316.58-1.853,1.068l-1.936-1.13-1.513,2.591,1.941,1.133c-.094.385-.14.748-.14,1.101s.046.715.14,1.1l-1.941,1.134,1.513,2.591,1.936-1.131c.537.489,1.167.852,1.853,1.068v2.238h3v-2.238c.687-.217,1.316-.58,1.853-1.068l1.936,1.131,1.513-2.591-1.941-1.134c.094-.385.14-.747.14-1.1Zm.434,2.593l-.504.863-1.747-1.02-.272.291c-.549.586-1.249.989-2.021,1.165l-.389.088v2.019h-1v-2.019l-.389-.088c-.773-.176-1.472-.579-2.021-1.165l-.272-.291-1.747,1.02-.504-.863,1.755-1.025-.119-.38c-.138-.439-.202-.817-.202-1.188s.064-.748.202-1.188l.119-.38-1.755-1.024.504-.863,1.747,1.02.273-.291c.549-.586,1.249-.989,2.021-1.165l.389-.088v-2.019h1v2.019l.389.088c.773.176,1.472.579,2.021,1.165l.273.291,1.747-1.02.504.863-1.755,1.024.119.38c.138.44.202.818.202,1.188s-.064.748-.202,1.188l-.119.38,1.755,1.025Z"/></svg> 
                        : <svg style={{fill:"rgb(88,0,172)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="m17,15c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.449-1-1s.449-1,1-1,1,.449,1,1-.449,1-1,1Zm-13-4h4v1h-4c-1.654,0-3,1.346-3,3v6H0v-6c0-2.206,1.794-4,4-4ZM14,6c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm14,6c0-.353-.046-.716-.14-1.101l1.941-1.133-1.513-2.591-1.936,1.13c-.537-.488-1.167-.851-1.853-1.068v-2.238h-3v2.238c-.687.217-1.316.58-1.853,1.068l-1.936-1.13-1.513,2.591,1.941,1.133c-.094.385-.14.748-.14,1.101s.046.715.14,1.1l-1.941,1.134,1.513,2.591,1.936-1.131c.537.489,1.167.852,1.853,1.068v2.238h3v-2.238c.687-.217,1.316-.58,1.853-1.068l1.936,1.131,1.513-2.591-1.941-1.134c.094-.385.14-.747.14-1.1Zm.434,2.593l-.504.863-1.747-1.02-.272.291c-.549.586-1.249.989-2.021,1.165l-.389.088v2.019h-1v-2.019l-.389-.088c-.773-.176-1.472-.579-2.021-1.165l-.272-.291-1.747,1.02-.504-.863,1.755-1.025-.119-.38c-.138-.439-.202-.817-.202-1.188s.064-.748.202-1.188l.119-.38-1.755-1.024.504-.863,1.747,1.02.273-.291c.549-.586,1.249-.989,2.021-1.165l.389-.088v-2.019h1v2.019l.389.088c.773.176,1.472.579,2.021,1.165l.273.291,1.747-1.02.504.863-1.755,1.024.119.38c.138.44.202.818.202,1.188s-.064.748-.202,1.188l-.119.38,1.755,1.025Z"/></svg>
                      }
                    </div> 
                    Account Settings
                </Link>
              </li>
              <li className="sidebar-contain">
                <Link to="/credits" className={`pages sidebar-contain ${activeTab === 'credits' ? 'active' : ''}`} onClick={() => setActiveTab('credits')}>
                <div className='sidebar-icon'>
                        {activeTab === 'credits' ? <svg style={{fill:"rgb(255,255,255)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="m17,15c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.449-1-1s.449-1,1-1,1,.449,1,1-.449,1-1,1Zm-13-4h4v1h-4c-1.654,0-3,1.346-3,3v6H0v-6c0-2.206,1.794-4,4-4ZM14,6c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm14,6c0-.353-.046-.716-.14-1.101l1.941-1.133-1.513-2.591-1.936,1.13c-.537-.488-1.167-.851-1.853-1.068v-2.238h-3v2.238c-.687.217-1.316.58-1.853,1.068l-1.936-1.13-1.513,2.591,1.941,1.133c-.094.385-.14.748-.14,1.101s.046.715.14,1.1l-1.941,1.134,1.513,2.591,1.936-1.131c.537.489,1.167.852,1.853,1.068v2.238h3v-2.238c.687-.217,1.316-.58,1.853-1.068l1.936,1.131,1.513-2.591-1.941-1.134c.094-.385.14-.747.14-1.1Zm.434,2.593l-.504.863-1.747-1.02-.272.291c-.549.586-1.249.989-2.021,1.165l-.389.088v2.019h-1v-2.019l-.389-.088c-.773-.176-1.472-.579-2.021-1.165l-.272-.291-1.747,1.02-.504-.863,1.755-1.025-.119-.38c-.138-.439-.202-.817-.202-1.188s.064-.748.202-1.188l.119-.38-1.755-1.024.504-.863,1.747,1.02.273-.291c.549-.586,1.249-.989,2.021-1.165l.389-.088v-2.019h1v2.019l.389.088c.773.176,1.472.579,2.021,1.165l.273.291,1.747-1.02.504.863-1.755,1.024.119.38c.138.44.202.818.202,1.188s-.064.748-.202,1.188l-.119.38,1.755,1.025Z"/></svg> 
                        : <svg style={{fill:"rgb(88,0,172)" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="22" height="22"><path d="m17,15c-1.103,0-2,.897-2,2s.897,2,2,2,2-.897,2-2-.897-2-2-2Zm0,3c-.551,0-1-.449-1-1s.449-1,1-1,1,.449,1,1-.449,1-1,1Zm-13-4h4v1h-4c-1.654,0-3,1.346-3,3v6H0v-6c0-2.206,1.794-4,4-4ZM14,6c0-3.309-2.691-6-6-6S2,2.691,2,6s2.691,6,6,6,6-2.691,6-6Zm-6,5c-2.757,0-5-2.243-5-5S5.243,1,8,1s5,2.243,5,5-2.243,5-5,5Zm14,6c0-.353-.046-.716-.14-1.101l1.941-1.133-1.513-2.591-1.936,1.13c-.537-.488-1.167-.851-1.853-1.068v-2.238h-3v2.238c-.687.217-1.316.58-1.853,1.068l-1.936-1.13-1.513,2.591,1.941,1.133c-.094.385-.14.748-.14,1.101s.046.715.14,1.1l-1.941,1.134,1.513,2.591,1.936-1.131c.537.489,1.167.852,1.853,1.068v2.238h3v-2.238c.687-.217,1.316-.58,1.853-1.068l1.936,1.131,1.513-2.591-1.941-1.134c.094-.385.14-.747.14-1.1Zm.434,2.593l-.504.863-1.747-1.02-.272.291c-.549.586-1.249.989-2.021,1.165l-.389.088v2.019h-1v-2.019l-.389-.088c-.773-.176-1.472-.579-2.021-1.165l-.272-.291-1.747,1.02-.504-.863,1.755-1.025-.119-.38c-.138-.439-.202-.817-.202-1.188s.064-.748.202-1.188l.119-.38-1.755-1.024.504-.863,1.747,1.02.273-.291c.549-.586,1.249-.989,2.021-1.165l.389-.088v-2.019h1v2.019l.389.088c.773.176,1.472.579,2.021,1.165l.273.291,1.747-1.02.504.863-1.755,1.024.119.38c.138.44.202.818.202,1.188s-.064.748-.202,1.188l-.119.38,1.755,1.025Z"/></svg>
                      }
                    </div> 
                    Credits
                </Link>
              </li>
              <li className="sidebar-contain">
                <Link to="/exports" className={`pages sidebar-contain ${activeTab === 'Exports' ? 'active' : ''}`} onClick={() => setActiveTab('Exports')}>
                <div className='sidebar-icon'>
                        {activeTab === 'Exports' ? <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16"><path fill="white" fill-rule="evenodd" d="M11.78 5.841a.75.75 0 0 1-1.06 0l-1.97-1.97v7.379a.75.75 0 0 1-1.5 0V3.871l-1.97 1.97a.75.75 0 0 1-1.06-1.06l3.25-3.25L8 1l.53.53l3.25 3.25a.75.75 0 0 1 0 1.061M2.5 9.75a.75.75 0 0 0-1.5 0V13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9.75a.75.75 0 0 0-1.5 0V13a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" clip-rule="evenodd"/></svg> :<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
                        <path fill="#5800ac" fill-rule="evenodd" d="M11.78 5.841a.75.75 0 0 1-1.06 0l-1.97-1.97v7.379a.75.75 0 0 1-1.5 0V3.871l-1.97 1.97a.75.75 0 0 1-1.06-1.06l3.25-3.25L8 1l.53.53l3.25 3.25a.75.75 0 0 1 0 1.061M2.5 9.75a.75.75 0 0 0-1.5 0V13a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V9.75a.75.75 0 0 0-1.5 0V13a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" clip-rule="evenodd"/></svg>}
                    </div>  
                    Exports
                </Link>
              </li>
              {/* <li className="sidebar-contain">
              <div className="flag sidebar-flag">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
              <mask id="circleFlagsUs0"><circle cx="256" cy="256" r="256" fill="#fff"/></mask>
              <g mask="url(#circleFlagsUs0)">
                <path fill="#eee" d="M256 0h256v64l-32 32l32 32v64l-32 32l32 32v64l-32 32l32 32v64l-256 32L0 448v-64l32-32l-32-32v-64z"/>
                <path fill="#d80027" d="M224 64h288v64H224Zm0 128h288v64H256ZM0 320h512v64H0Zm0 128h512v64H0Z"/>
                <path fill="#0052b4" d="M0 0h256v256H0Z"/>
                <path fill="#eee" d="m187 243l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67zm162-81l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Zm162-82l57-41h-70l57 41l-22-67Zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Z"/>
              </g>
            </svg>
            <div className="language">Eng (US)</div>
            <div className="arrow-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
              </svg>
            </div>
          </div>
          </li>
          <li><div className='user-profile1'>
           <img className='profile-image1' src={profile}></img>
           <div className='user-name'>
                <h4 className='name'>Faisal</h4>
                <p className='role'>Admin</p>
           </div>
           <div className='arrow-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                    <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
                </svg>
            </div>
        </div></li> */}
              <li className="sidebar-contain" style={{position:"absolute" , bottom:"0px"}}>
                <Link to="#" className="pages sidebar-contain" onClick={handleLogout}>
                  <div className='sidebar-icon'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                      <g fill="none" stroke="#5800ac" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                        <path d="M14 8V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2v-2"/>
                        <path d="M9 12h12l-3-3m0 6l3-3"/>
                      </g>
                    </svg>
                  </div>
                  Sign out
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <header className="section-t-space" style={{paddingTop:'10px'}}>
        <div className="custom-container">
          <div className="header-panel" style={{height:'50px'}}>
            <button className="sidebar-btn" onClick={toggleSidebar}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                    <path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6h18M3 12h18M3 18h18"/>
                </svg>
            </button>
            {/* <div className="input-search">
              <input type="text" placeholder="Search.... " required="" />
              <span className="input-toggle">
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                      <path fill="#757575" d="M464 428L339.92 303.9a160.48 160.48 0 0 0 30.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0 0 94.58-30.72L428 464ZM209.32 319.69a110.38 110.38 0 1 1 110.37-110.37a110.5 110.5 0 0 1-110.37 110.37"/>
                  </svg>
              </span>
            </div> */}
        <div className="flag navbar-flag">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                <mask id="circleFlagsUs0"><circle cx="256" cy="256" r="256" fill="#fff"/></mask>
                <g mask="url(#circleFlagsUs0)">
                  <path fill="#eee" d="M256 0h256v64l-32 32l32 32v64l-32 32l32 32v64l-32 32l32 32v64l-256 32L0 448v-64l32-32l-32-32v-64z"/>
                  <path fill="#d80027" d="M224 64h288v64H224Zm0 128h288v64H256ZM0 320h512v64H0Zm0 128h512v64H0Z"/>
                  <path fill="#0052b4" d="M0 0h256v256H0Z"/>
                  <path fill="#eee" d="m187 243l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67zm162-81l57-41h-70l57 41l-22-67zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Zm162-82l57-41h-70l57 41l-22-67Zm-81 0l57-41H93l57 41l-22-67zm-81 0l57-41H12l57 41l-22-67Z"/>
                </g>
              </svg>
              <div className="language">Eng (US)</div>
              <div className="arrow-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                  <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
                </svg>
              </div>
            </div>
        {/* <div className='date'>
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 20 20">
                <path fill="black" d="M5.673 0a.7.7 0 0 1 .7.7v1.309h7.517v-1.3a.7.7 0 0 1 1.4 0v1.3H18a2 2 0 0 1 2 1.999v13.993A2 2 0 0 1 18 20H2a2 2 0 0 1-2-1.999V4.008a2 2 0 0 1 2-1.999h2.973V.699a.7.7 0 0 1 .7-.699M1.4 7.742v10.259a.6.6 0 0 0 .6.6h16a.6.6 0 0 0 .6-.6V7.756zm5.267 6.877v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zm-8.333-3.977v1.666H5v-1.666zm4.166 0v1.666H9.167v-1.666zm4.167 0v1.666h-1.667v-1.666zM4.973 3.408H2a.6.6 0 0 0-.6.6v2.335l17.2.014V4.008a.6.6 0 0 0-.6-.6h-2.71v.929a.7.7 0 0 1-1.4 0v-.929H6.373v.92a.7.7 0 0 1-1.4 0z"/>
            </svg>
        </div>
        <div className='message'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
                <path fill="none" stroke="black" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 11v.01M8 11v.01m8-.01v.01M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1-3 3h-5l-5 3v-3H6a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3z"/>
            </svg>
        </div> */}
        <div className='notification1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                <path fill="black" d="M28.707 19.293L26 16.586V13a10.014 10.014 0 0 0-9-9.95V1h-2v2.05A10.014 10.014 0 0 0 6 13v3.586l-2.707 2.707A1 1 0 0 0 3 20v3a1 1 0 0 0 1 1h7v.777a5.15 5.15 0 0 0 4.5 5.199A5.006 5.006 0 0 0 21 25v-1h7a1 1 0 0 0 1-1v-3a1 1 0 0 0-.293-.707M19 25a3 3 0 0 1-6 0v-1h6Zm8-3H5v-1.586l2.707-2.707A1 1 0 0 0 8 17v-4a8 8 0 0 1 16 0v4a1 1 0 0 0 .293.707L27 20.414Z"/>
            </svg>
        </div>
        <div className='user-profile'>
           <img className='profile-image' src={profile}></img>
           <div className='user-name'>
                <h4 className='name'>{username}</h4>
                {/* <p className='role'>Admin</p> */}
           </div>
           {/* <div className='arrow-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 7">
                    <path fill="black" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/>
                </svg>
            </div> */}
        </div>
                          
          </div>
        </div>
      </header>
    </div>
  );
}

export default Sidebar;
