import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./Auth/login";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import profile from "./assets/profile-image.png";

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";

import "./Account.css";

import { useEffect } from "react";
import apiClient from "./Auth/apiClient"; // Import the custom axios instance
import Account_Info from "./account/account_info";
import Email_set from "./account/email_set";
import Customer from "./account/customer";
import Email_Notification from "./account/email_notification";
import Gmail_Auth from "./account/gmail_auth";

function Account_setting() {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [error, setError] = useState(null);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [exportsdata, setExportData] = useState([]);

  const username = localStorage.getItem("username");

  const fetchData = async () => {
    try {
      const response = await apiClient.get("/v1/profile");

      setData(response.data.data.user);
      setIsDataAvailable(true);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login if unauthorized
        navigate("/login");
      } else {
        console.error("Error fetching data:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="Account-setting" style={{ overflow: "hidden" }}>
      <Sidebar />
      <div className="home-body">
        {/* <span>
          Dashboard &gt; <b>Account_settings</b>
        </span> */}

        <div className="dashboard">
          {isDataAvailable && (
            <Account_Info data={data} />
          )}

          {/* <Email_set /> */}
          <Email_Notification />
          <Customer />

         


         {/* <Gmail_Auth /> */}

        </div>
      </div>
    </div>
  );
}

export default Account_setting;
