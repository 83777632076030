import React, { useState,useEffect } from "react";
import "./Credit_page.css";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import apiClient from './Auth/apiClient'; // Import the custom axios instance


function Credit_page() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isData , setIsData] = useState(false);

  const fetchData = async () => {
    try {
      const response = await apiClient.get('/v1/profile');
      console.log(response.data);
      setIsData(true);
      setData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to login if unauthorized
        navigate('/login');
      } else {
        console.error('Error fetching data:', error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="dashboard-container">
      <Sidebar />
    

      {/* Credits Usage Section */}
      <div className="credits-usage mt-5 bg-white rounded-2 shadow-sm p-3 mb-3 bg-body-tertiary rounded">
        <div className="d-flex justify-content-between border-bottom p-3 flex-wrap">
          <div className="credit-header">
            Credits Usage | Jul 22, 2024 - Aug 22, 2024
          </div>
          <div className="credit-header text-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                fill="black"
                d="M11 1.799c-4.445 0-8.061 3.562-8.169 7.996V10H.459l3.594 3.894L7.547 10H4.875v-.205C4.982 6.492 7.683 3.85 11 3.85c3.386 0 6.131 2.754 6.131 6.15S14.386 16.15 11 16.15a6.1 6.1 0 0 1-3.627-1.193l-1.406 1.504A8.13 8.13 0 0 0 11 18.199c4.515 0 8.174-3.67 8.174-8.199S15.515 1.799 11 1.799M10 5v5a1 1 0 0 0 .293.707l3.2 3.2c.283-.183.55-.389.787-.628L12 11V5z"
              />
            </svg>{" "}
            Estimate Credit Renewal on: Aug 22, 2024 12:30 PM{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="black"
                d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8"
              />
            </svg>
          </div>
        </div>
        <div className="credit-body p-3">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="border-end px-3 mb-4 mb-md-0" style={{ flex: "1 1 30%" }}>
              <span className="ms-3">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="#8cfac3"
                    d="M0 4c0-4 4-4 4-4h28s4 0 4 4v28s0 4-4 4H4s-4 0-4-4z"
                  />
                  <path
                    fill="#15b366"
                    d="M28 8h4v20h-4zm-6 4h4v16h-4zm-6 4h4v12h-4zm-6 4h4v8h-4zm-6 4h4v4H4z"
                  />
                </svg>
                <br />
              </span>
              <div className="mt-3">
                <div className="card-header">Export credits usage</div>
                <div className="credit-usage">
                  <span className="usage-count">{ isData && data && data.data && data.data.user && (data.data.user.active_credit.used+" of "+data.data.user.active_credit.total+" export / mo")}</span>
                  <button className="buy-button">Buy more</button>
                </div>
                <div className="progress-bar-container">
                  <div className="progress-bar">
                    <div className="progress" style={{ width: "0%" }}></div>
                  </div>
                </div>
                <p className="credit-info">
                  Export credits are charged when you export a contact outside Revenuewave. For example, when you use CSV, CRM, or API enrichment, sync to any system outside of Revenuewave, like Outrich or Salesloft. <Link>Learn more</Link>
                </p>
                <p className="mt-3 mb-3">
                  Your team doesn't allow to share export credits.
                </p>
              </div>
            </div>
            <div className="border-end px-3 mb-4 mb-md-0" style={{ flex: "1 1 30%" }}>
              <span className="ms-3"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 36 36"
              >
                <path
                  fill="#fec6e3"
                  d="M0 4c0-4 4-4 4-4h28s4 0 4 4v28s0 4-4 4H4s-4 0-4-4z"
                />
                <path
                  fill="#cd2e7c"
                  d="M28 8h4v20h-4zm-6 4h4v16h-4zm-6 4h4v12h-4zm-6 4h4v8h-4zm-6 4h4v4H4z"
                />
              </svg>
              </span>
              <div className="mt-3">
                <div className="card-header">Email credits usage</div>
                <div className="credit-usage">
                  <span className="usage-count">∞ of ∞ emails / mo</span>
                  <button className="upgrade-button">Upgrade</button>
                </div>
                <div className="progress-bar-container">
                  <div className="progress-bar">
                    <div className="progress" style={{ width: "67%" }}></div>
                  </div>
                </div>
                <p className="credit-info">
                  Email credits provide access to a contact's personal or business email address. We only charge 1 email credit per verified email.<Link> Fair Use Policy</Link> limits plan to 100 email credits per cycle to prevent abuse.<Link> Learn more</Link>
                </p>
                <p className="mt-3 mb-3">
                  Your team doesn't allow to share email credits.
                </p>
                <p className="mt-3 mb-3 bg-warning-subtle p-2">
                 Unlock Unlimited credits by linking a corporate mailbox. <Link>Link Mailbox</Link> 
                </p>
              </div>
            </div>
            <div className="px-3" style={{ flex: "1 1 30%" }}>
              <span className="ms-3"><svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 36 36"
              >
                <path
                  fill="#ffdabd"
                  d="M0 4c0-4 4-4 4-4h28s4 0 4 4v28s0 4-4 4H4s-4 0-4-4z"
                />
                <path
                  fill="#ff9652"
                  d="M28 8h4v20h-4zm-6 4h4v16h-4zm-6 4h4v12h-4zm-6 4h4v8h-4zm-6 4h4v4H4z"
                />
              </svg>
              </span>
              <div className="mt-3">
                <div className="card-header">Mobile Number credits usage</div>
                <div className="credit-usage">
                  <span className="usage-count">∞ of ∞ numbers / mo</span>
                  <button className="buy-button">Buy more</button>
                </div>
                <div className="progress-bar-container">
                  <div className="progress-bar">
                    <div className="progress" style={{ width: "0%" }}></div>
                  </div>
                </div>
                <p className="credit-info">
                  Mobile Number credits allow you to unlock a contact's phone number, increasing your chances of reaching prospects on the go. Each unlock costs 1 credit.<Link> Learn more</Link>
                </p>
                <p className="mt-3 mb-3">
                  Your team doesn't allow to share mobile credits.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body"></div>
      </div>

      <div style={{display:"none"}} className="credits-usage mt-5 bg-white rounded-2 shadow-sm p-3 mb-3 bg-body-tertiary rounded">
        <div className="d-flex justify-content-between border-bottom  p-3 flex-wrap">
          <div className="credit-header">
            Subscription
          </div>
          <div className="credit-header">
          <button className="change-plan-button">Change plan</button>
          </div>
        </div>
        <div className="subscription-header mt-4">
          <span>Free $0 / mo</span>
          
        </div>
        <div className="subscription-details flex-wrap">
          <div className="subscription-item border-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 256 256"><path fill="#0062ff" d="M230.92 212c-15.23-26.33-38.7-45.21-66.09-54.16a72 72 0 1 0-73.66 0c-27.39 8.94-50.86 27.82-66.09 54.16a8 8 0 1 0 13.85 8c18.84-32.56 52.14-52 89.07-52s70.23 19.44 89.07 52a8 8 0 1 0 13.85-8M72 96a56 56 0 1 1 56 56a56.06 56.06 0 0 1-56-56"/></svg>
            <span className="item-title mt-2">Users</span>
            <span className="item-detail">5 users / mo</span>
          </div>
          <div className="subscription-item border-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#0062ff" d="M21.22 12a3 3 0 0 0 .78-2a3 3 0 0 0-3-3h-5.18A3 3 0 0 0 11 3H5a3 3 0 0 0-3 3a3 3 0 0 0 .78 2a3 3 0 0 0 0 4a3 3 0 0 0 0 4A3 3 0 0 0 2 18a3 3 0 0 0 3 3h14a3 3 0 0 0 2.22-5a3 3 0 0 0 0-4M11 19H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2m0-4H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2m0-4H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2m0-4H5a1 1 0 0 1 0-2h6a1 1 0 0 1 0 2m8.69 11.71A.93.93 0 0 1 19 19h-5.18a2.87 2.87 0 0 0 0-2H19a1 1 0 0 1 1 1a1 1 0 0 1-.31.71m0-4A.93.93 0 0 1 19 15h-5.18a2.87 2.87 0 0 0 0-2H19a1 1 0 0 1 1 1a1 1 0 0 1-.31.71m0-4A.93.93 0 0 1 19 11h-5.18a2.87 2.87 0 0 0 0-2H19a1 1 0 0 1 1 1a1 1 0 0 1-.31.71"/></svg>
            <span className="item-title mt-2">Export Credits</span>
            <span className="item-detail mt-2">50 export credits / mo</span>
          </div>
          <div className="subscription-item border-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#0062ff" d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7L4 8v10h16V8zm0-2l8-5H4zM4 8V6v12z"/></svg>
            <span className="item-title mt-2">Email credits</span>
            <span className="item-detail mt-2">100 emails / mo</span>
          </div>
          <div className="subscription-item">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="#0062ff" fill-rule="evenodd" d="M5.84 9.856a17.22 17.22 0 0 0 8.922 8.663l.012.005l.764.34a2.25 2.25 0 0 0 2.74-.737l1.274-1.763a.25.25 0 0 0-.046-.341l-2.224-1.795a.25.25 0 0 0-.358.046l-.866 1.168a.75.75 0 0 1-.912.237a13.4 13.4 0 0 1-6.67-6.67a.75.75 0 0 1 .237-.912L9.88 7.23a.25.25 0 0 0 .046-.358L8.132 4.648a.25.25 0 0 0-.341-.046l-1.773 1.28a2.25 2.25 0 0 0-.732 2.756zm8.33 10.041a18.72 18.72 0 0 1-9.693-9.416l-.002-.002l-.554-1.22A3.75 3.75 0 0 1 5.14 4.666l1.773-1.28a1.75 1.75 0 0 1 2.386.32l1.795 2.225a1.75 1.75 0 0 1-.32 2.505l-.67.496a11.9 11.9 0 0 0 5.118 5.118l.497-.67a1.75 1.75 0 0 1 2.504-.32l2.225 1.795a1.75 1.75 0 0 1 .32 2.387l-1.274 1.764a3.75 3.75 0 0 1-4.566 1.229z" clip-rule="evenodd"/></svg>
            <span className="item-title mt-2">Mobile numbers</span>
            <span className="item-detail mt-2">25 numbers / mo</span>
          </div>
        </div>
      </div>
      

     
      {/* Plan Overview Section */}
      <div style={{display:"none"}} className="plan-overview-section">
        <div className="d-flex justify-content-between border-bottom  p-3 flex-wrap">
          <div>
          <span className="plan-header ">Free Plan</span><br/>
          <p>You are on a free plan and your credits will refresh on Aug 22, 2024.</p>
          </div>
          <div>
          <button className="purchase-plan-button">Purchase Plan</button>
          </div>
        </div>
        <div className="plan-details">
          <div className="d-flex justify-content-between mb-3 mt-3 text-black-50">
            <div>What's Included</div>
            <div>Price</div>
          </div>
          <div className="plan-item">
            <span className="plan-title-1">Users</span>
            <span className="plan-detail-2">Free Users x 5</span>
            <span className="plan-detail">$0/mo</span>
          </div>
          <hr/>
          <div className="plan-item">
            <span className="plan-title-1">Credits (In your Plan)</span>
            <span className="plan-detail-1">
              100 Email Credits / mo <br /> 25 Mobile Number Credits / mo <br />{" "}
              50 Export Credits / mo <br/>
              <p className="mt-3 mb-3 bg-warning-subtle p-2 rounded-2">
                 Unlock Unlimited credits by linking a corporate mailbox. <Link>Link Mailbox</Link> 
                </p>
            </span>
            <span className="plan-title"></span>
          </div>
          <hr/>
          <div className="plan-item">
            <span className="plan-title-1">Add-On Credits</span>
            <span className="plan-detail-3">25 Temporary Export Credits</span>
            <span className="plan-detail">Free</span>
          </div>
          <hr/>
          <div className="plan-item">
            <span className="plan-title-1">Monthly Total</span>
            <span className="plan-detail">$0 / mo</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Credit_page;
