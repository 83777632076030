import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import apiClient from './Auth/apiClient'; // Adjust the import based on your project structure
import './userList.css';

Modal.setAppElement('#root');

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [addNewUser, setAddNewUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    setNewUser({
      "name": null,
      "password": null,
      "email": null,
      "phone": null,
      "line1": null,
      "line2": null,
      "city": null,
      "state": null,
      "country": null,
      "postal_code": null,
      "is_admin": false,
      "is_active": false
    });
  } , {});

  const fetchUsers = async () => {
    try {
      const response = await apiClient.get('/v1/users');
      setUsers(response.data.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const openViewModal = (user) => {
    setSelectedUser(user);
    setIsViewModalOpen(true);
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setSelectedUser(null);
  };

  const openEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalOpen(true);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  const openAddNewUser = () => {
    setAddNewUser(true);
  };

  const closeAddNewUser = () => {
    setAddNewUser(false);
    // setSelectedUser(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser({
      ...selectedUser,
      [name]: value,
    });
  };
  
  const handleAddUserChange = (e) => {
    const { name, value } = e.target;
    setNewUser({
      ...newUser,
      [name] : value,
    });
    console.log("onChange -> " , name , value);
    console.log("newUser -> " , newUser);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.put(`/v1/users/${selectedUser.id}`, selectedUser);
      // Assuming the API returns the updated user
      const updatedUser = response.data;
      // setUsers(users.map(user => (user.id === updatedUser.id ? updatedUser : user)));
      fetchUsers();
      closeEditModal();
    } catch (err) {
      setError('Failed to update user. Please try again.');
    }
  };

  const handleAddUserSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await apiClient.post(`/v1/users`, newUser);
      // Assuming the API returns the updated user
      // const updatedUser = response.data;
      console.log( "====-=-===> aduser " , response)
      fetchUsers();
      closeAddNewUser();
      // users.push(response.data.data[0]);
      // setUsers( users.concat([response.data.data]) );
    } catch (err) {
      setError('Failed to update user. Please try again.');
    }
  };

  const deleteUser = async (user_id) => {
    // e.preventDefault();
    try {
      const response = await apiClient.delete(`/v1/users/${user_id}`);
      console.log(response);
      fetchUsers();
    } catch (err) {
      console.log(err);
    }

  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div>
      <h1>User List</h1>
      <button onClick={() => { openAddNewUser() }}>+ Add</button>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Admin</th>
            <th>Active</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td onClick={() => openViewModal(user)} style={{ cursor: 'pointer' }}>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.is_admin ? 'Yes' : 'No'}</td>
              <td>{user.is_active ? 'Yes' : 'No'}</td>
              <td>
                {/* <button onClick={() => openViewModal(user)}>View</button> */}
                <button onClick={() => openEditModal(user)}>✏️ Edit</button>
                <button onClick={() => deleteUser(user.id)}>🗑️ Delete</button>
                <button onClick={() => openEditModal(user)}>✏️ Password</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* View Modal */}
      <Modal
        isOpen={isViewModalOpen}
        onRequestClose={closeViewModal}
        contentLabel="View User"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {selectedUser && (
          <div>
            <h2>{selectedUser.name}</h2>
            <p>Email: {selectedUser.email}</p>
            <p>Phone: {selectedUser.phone || 'N/A'}</p>
            <p>Address: {`${selectedUser.line1 || ''} ${selectedUser.line2 || ''} ${selectedUser.city || ''} ${selectedUser.state || ''} ${selectedUser.country || ''} ${selectedUser.postal_code || ''}`}</p>
            <p>Admin: {selectedUser.is_admin ? 'Yes' : 'No'}</p>
            <p>Active: {selectedUser.is_active ? 'Yes' : 'No'}</p>
            {selectedUser.active_credit && (
              <div>
                <h4>Active Credit</h4>
                <p>Credits Remaining: {selectedUser.active_credit.remaining}</p>
                <p>Credits Expire At: {selectedUser.active_credit.expired_at}</p>
              </div>
            )}
            <button onClick={closeViewModal} className="close-btn">Close</button>
          </div>
        )}
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit User"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {selectedUser && (
          <div>
            <h2>Edit User</h2>
            <form onSubmit={handleEditSubmit}>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  value={selectedUser.name}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Password:</label>
                <input
                  type="text"
                  name="password"
                  value={selectedUser.password}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={selectedUser.email}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Phone:</label>
                <input
                  type="text"
                  name="phone"
                  value={selectedUser.phone || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Line 1:</label>
                <input
                  type="text"
                  name="line1"
                  value={selectedUser.line1 || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Line 2:</label>
                <input
                  type="text"
                  name="line2"
                  value={selectedUser.line2 || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  value={selectedUser.city || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>State:</label>
                <input
                  type="text"
                  name="state"
                  value={selectedUser.state || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Country:</label>
                <input
                  type="text"
                  name="country"
                  value={selectedUser.country || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Postal Code:</label>
                <input
                  type="text"
                  name="postal_code"
                  value={selectedUser.postal_code || ''}
                  onChange={handleEditChange}
                />
              </div>
              <div>
                <label>Admin:</label>
                <input
                  type="checkbox"
                  name="is_admin"
                  checked={selectedUser.is_admin}
                  onChange={() => setSelectedUser({ ...selectedUser, is_admin: !selectedUser.is_admin })}
                />
              </div>
              <div>
                <label>Active:</label>
                <input
                  type="checkbox"
                  name="is_active"
                  checked={selectedUser.is_active}
                  onChange={() => setSelectedUser({ ...selectedUser, is_active: !selectedUser.is_active })}
                />
              </div>
              {/* Add more fields as necessary */}
              <button type="submit" className="save-btn">Save</button>
            </form>
            <button onClick={closeEditModal} className="close-btn">Cancel</button>
          </div>
        )}
      </Modal>

      {/* Add New User Modal */}
      <Modal
        isOpen={addNewUser}
        onRequestClose={closeAddNewUser}
        contentLabel="Add User"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        {addNewUser && (
          <div>
            <h2>Edit User</h2>
            <form onSubmit={handleAddUserSubmit}>
              <div>
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Password:</label>
                <input
                  type="text"
                  name="password"
                  value={newUser.password}
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Email:</label>
                <input
                  type="email"
                  name="email"
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Phone:</label>
                <input
                  type="text"
                  name="phone"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Line 1:</label>
                <input
                  type="text"
                  name="line1"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Line 2:</label>
                <input
                  type="text"
                  name="line2"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>State:</label>
                <input
                  type="text"
                  name="state"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Country:</label>
                <input
                  type="text"
                  name="country"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Postal Code:</label>
                <input
                  type="text"
                  name="postal_code"
                  
                  onChange={handleAddUserChange}
                />
              </div>
              <div>
                <label>Admin:</label>
                <input
                  type="checkbox"
                  name="is_admin"
                  // onChange={ (e) => {console.log("changed - " , e.target.checked)} }
                  onChange={ (e) => setNewUser({ ...newUser, is_admin: !newUser.is_admin }) }
                  // checked={false}
                  // onChange={() => setSelectedUser({ ...selectedUser, is_admin: !selectedUser.is_admin })}
                />
              </div>
              {/* <div>
                <label>Active:</label>
                <input
                  type="checkbox"
                  name="is_active"
                  onChange={ (e) => {console.log("changed - " , e.target.checked)} }
                  // checked={false}
                  // onChange={() => setSelectedUser({ ...selectedUser, is_active: !selectedUser.is_active })}
                />
              </div> */}
              {/* Add more fields as necessary */}
              <button type="submit" className="save-btn">Save</button>
            </form>
            <button onClick={closeAddNewUser} className="close-btn">Cancel</button>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default UserList;
