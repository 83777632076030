import feather from "feather-icons";
import Sidebar from "./Sidebar";

import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Login from "./Auth/login";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import profile from "./assets/profile-image.png";

import { Doughnut } from "react-chartjs-2";

import { Link } from "react-router-dom";
import Select from "react-select";
import InfiniteScroll from "react-infinite-scroller";

import "./App.css";
import "./leads.css";

import { useEffect, useCallback } from "react";
import Modal from "./modal";
import ImportForm from "./ImportForm";
import LeadForm from "./LeadForm";
import apiClient from "./Auth/apiClient"; // Import the custom axios instance
import debounce from "lodash.debounce";
import PaginationComponent from "./paginationcomponent";
import ExportForm from "./exportform";

import InfoTooltip from "./InfoTooltip";

function Leads() {
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [Seniorities, setSeniorities] = useState([]);
  const [Emprange, setEmprange] = useState([]);
  const [Revrange, setRevrange] = useState([]);
  const [Address, setAddress] = useState([]);
  const [Department, setDepartment] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [loadingLeads, setLoadingLeads] = useState(false);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loadingCheckbox, setLoadingCheckbox] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);
  const [loadingIndustries, setLoadingIndustries] = useState(false);
  const [LoadingDepartment, setLoadingDepartment] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageNumberIndustries, setPageNumberIndustries] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreIndustries, setHasMoreIndustries] = useState(true);
  const [activeTab, setActiveTab] = useState("Leads"); // State to track active tab
  const [activeBtn, setactiveBtn] = useState(null); // State to track active tab
  const [recordsToShow, setRecordsToShow] = useState(10); // State for records dropdown
  const [DataToShow, setDataToShow] = useState(10); // State for records dropdown
  const [CompanyToShow, setCompanyToShow] = useState(10); // State for records dropdown
  const [sortOrder, setSortOrder] = useState("Newest");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalPagescompany, settotalPagescompany] = useState(0);
  const [exportstatus, setexportstatus] = useState(false);
  const [exportfail, setexportfail] = useState(false);
  const [ShowModalexport, setShowModalexport] = useState(false);
  const [LeadsAllID, setLeadsAllID] = useState("");
  const [loading, setLoading] = useState([]);

  let initialFilters = {};
  if (activeTab == "Leads") {
    const initialFilters = {
      first_name: "",
      last_name: "",
      titles: "",
      seniorities: "",
      companyDetails: "",
      companies: "",
      companies_domain: "",
      industries: "",
      employee_ranges: "",
      revenue_ranges: "",
      keywords: "",
      technologies: "",
      departments: "",
      work: "",
      mobile: "",
      email: "",
      contact_address: "",
      company_location: "",
    };
  } else {
    const initialFilters = {
      first_name: "",
      last_name: "",
      titles: "",
      seniorities: "",
      companyDetails: "",
      companies: "",
      companies_domain: "",
      industries: "",
      employee_ranges: "",
      revenue_ranges: "",
      keywords: "",
      technologies: "",
      departments: "",
      work: "",
      mobile: "",
      email: "",
      contact_address: "",
      company_location: "",
    };
  }

  function formatNumber(value) {
    console.log( "Got it - ",value);
    if (value >= 1e9) {
      // For billions
      return Math.round(value / 1e8) / 10 + 'B';
    } else if (value >= 1e6) {
      // For millions
      return Math.round(value / 1e5) / 10 + 'M';
    } else if (value >= 1e3) {
      // For thousands
      return Math.round(value / 1e2) / 10 + 'k';
    } else {
      // For numbers less than 1000
      return value.toString();
    }
  }

  const [filters, setFilters] = useState(initialFilters);
  const [appliedFilters, setAppliedFilters] = useState({});

  const [checkedLeads, setCheckedLeads] = useState([]);
  // State to manage the header checkbox status
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  // Sample data for leads
  const applyFilters = () => {
    setAppliedFilters(filters);
  };

  // Handler for individual checkboxes
  const handleCheckboxChange1 = (id) => {
    setCheckedLeads((prev) => {
      const isChecked = prev.includes(id);
      const updatedLeads = isChecked
        ? prev.filter((leadId) => leadId !== id)
        : [...prev, id];
      // console.log('Updated checkedLeads:', updatedLeads);
      return updatedLeads;
    });
  };

  const handleHeaderCheckboxChange = (event) => {
    const checked = event.target.checked;
    setIsHeaderChecked(checked);
    const updatedLeads = checked ? leads.map((lead) => lead.id) : [];
    setCheckedLeads(updatedLeads);
    // console.log('Header checkbox changed:', updatedLeads);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (activeTab === "Leads") {
        setLoadingLeads(true);
      } else if (activeTab === "Companies") {
        setLoadingCompanies(true);
      }
      setLoadingCheckbox(true);

      try {
        console.log(filters);
        const responses = await Promise.all([
          activeTab === "Leads"
            ? apiClient.get(
                `/v1/leads?${new URLSearchParams({
                  ...filters,
                  per_page: DataToShow,
                  page: currentPage,
                }).toString()}`
              )
            : null,
          activeTab === "Company"
            ? apiClient.get(
                `/v1/companies?${new URLSearchParams({
                  ...filters,
                  per_page: DataToShow,
                  page: currentPage,
                }).toString()}`
              )
            : null,
          apiClient.get("/v1/checkboxes"),
        ]);
        // console.log(responses[1].data)
        if (activeTab === "Leads" && responses[0]) {
          const leadsData = responses[0].data.leads.data;
          setLeads(leadsData);
          const leadIDs = leadsData.map((lead) => lead.id);
          setLeadsAllID(leadIDs);
          setTotalPages(responses[0].data.leads.last_page);
        } else if (activeTab === "Company" && responses[1]) {
          setCompanies(responses[1].data.com$companies.data);

          settotalPagescompany(responses[1].data.com$companies.last_page);
        }

        if (responses[2]) {
          setSeniorities(responses[2].data.seniorities);
          setEmprange(responses[2].data.employeeRange);
          setRevrange(responses[2].data.revenueRange);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate("/login");
        } else {
          console.error("Error fetching data:", error);
        }
      } finally {
        setLoadingLeads(false);
        setLoadingCompanies(false);
        setLoadingCheckbox(false);
      }
    };

    fetchData();
    fetchFilterDepartments();
  }, [appliedFilters, currentPage, navigate, DataToShow, activeTab]);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1); // react-paginate uses zero-based index
  };

  const handleToggle = (index) => {
    setIsOpen((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  // Handle selection change (e.g., from dropdowns)
  const handleSelectChange = (selectedOptions, actionMeta) => {
    // Check if the selection is empty
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    // Update filters state with the selected values
    setFilters((prevFilters) => ({
      ...prevFilters,
      [actionMeta.name]: selectedValues,
    }));
  };

  const removeSpecialCharacters = (str) => {
    return str.replace(/[^\w\s]/gi, ""); // Remove all special characters except letters, numbers, and spaces
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [apiCallsCount, setApiCallsCount] = useState(0);

  useEffect(() => {
    if (apiCallsCount < 2) {
      fetchFilterCountry(pageNumber, "");
    }
  }, [pageNumber, apiCallsCount]);

  const fetchFilterCountry = useCallback(
    async (page, query) => {
      setLoadingAddress(true);
      try {
        const response = await apiClient.get(
          `/v1/company-addresses?page=${page}&search=${query}`
        );
        const fetchedOptions = response.data.data.map((option) => ({
          value: option.id,
          label: removeSpecialCharacters(option.name),
        }));

        setAddress((prevOptions) => {
          return page === 1
            ? fetchedOptions
            : [...prevOptions, ...fetchedOptions];
        });

        setHasMore(!!response.data.next_page_url);

        if (apiCallsCount < 2) {
          setApiCallsCount(apiCallsCount + 1); // Increment API calls count
        }
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoadingAddress(false);
      }
    },
    [apiCallsCount]
  );

  const fetchFilterIndustries = useCallback(async (page) => {
    setLoadingIndustries(true);
    try {
      const response = await apiClient.get(`/v1/industries?page=${page}`);
      const fetchedOptions = response.data.data.map((option) => ({
        value: option.id,
        label: removeSpecialCharacters(option.name), // Clean the label
      }));

      setIndustries((prevOptions) => [...prevOptions, ...fetchedOptions]);
      setHasMoreIndustries(!!response.data.next_page_url);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  }, []);

  const fetchFilterDepartments = useCallback(async (query=null,page=1) => {
    setLoadingDepartment(true);
    try {
      var response;
      if( query !== null  && query !== "") {
        console.log("Query got --> " , query);
        response = await apiClient.get(`/v1/departments?search=`+ encodeURIComponent(query));
      } else {
        console.log("Simple --> ");
        response = await apiClient.get(`/v1/departments`);
      }
      const fetchedOptions = response.data.data.map((option) => ({
        value: option.id,
        label: removeSpecialCharacters(option.name), // Clean the label
      }));
      setDepartment(fetchedOptions);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      console.log("====>");
    }
  }, []);

  

  const loadMoreAddress = useCallback(
    debounce(() => {
      if (!loadingAddress && hasMore && apiCallsCount < 2) {
        const nextPage = pageNumber + 1;
        setPageNumber(nextPage);
        fetchFilterCountry(nextPage, searchQuery);
      }
    }, 500),
    [
      loadingAddress,
      hasMore,
      pageNumber,
      fetchFilterCountry,
      searchQuery,
      apiCallsCount,
    ]
  );

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    setPageNumber(1); // Reset page number

    if (apiCallsCount >= 2) {
      fetchFilterCountry(1, query); // Fetch data only when there is a search query and API calls limit is reached
      setHasMore(false); // Disable infinite scroll to prevent further API calls
    }
  };

  const handleSearchDepartment = (query) => {
    // setSearchQuery(query);
    // setPageNumber(1); // Reset page number
    console.log("===-=-===>",query);
    fetchFilterDepartments(query=query);
    // if (apiCallsCount >= 2) {
    //   fetchFilterCountry(1, query); // Fetch data only when there is a search query and API calls limit is reached
    //   setHasMore(false); // Disable infinite scroll to prevent further API calls
    // }
  };

  const loadMoreindustries = useCallback(
    debounce(() => {
      if (!loadingIndustries && hasMoreIndustries) {
        const nextPage = pageNumberIndustries + 1;
        setPageNumberIndustries(nextPage);
        fetchFilterIndustries(nextPage);
      }
    }, 2000),
    [
      loadingIndustries,
      hasMoreIndustries,
      pageNumberIndustries,
      fetchFilterIndustries,
    ]
  );

  const options = Seniorities.map((seniority) => ({
    value: seniority.id,
    label: seniority.name,
  }));

  const optionsemp = Emprange.map((employee) => ({
    value: employee.id,
    label: employee.name,
  }));

  const optionsrev = Revrange.map((revenue) => ({
    value: revenue.id,
    label: revenue.name,
  }));

  // console.log(LeadsAllID);

  let filterData = [];
  // const [filterData, setFilterData] = useState([]);

  function checkSearchType() {
    if (activeTab === "Leads") {
      console.log("======> ")
      // setFilterData([]);
      // setFilterData([
      filterData = [
        {
          name: "Name",
          type: "double-input",
          stateIndex: 0,
          placeholder: "Enter Name",
          inname: "names",
        },
        {
          name: "Title",
          type: "input",
          stateIndex: 0,
          placeholder: "Enter Title",
          outname: "titles",
          inname: "titles",
        },
        {
          name: "Contact Info",
          type: "contact-info",
          stateIndex: 0,
          placeholder: "Enter Mobile",
          inname: "mobile",
        },
        {
          name: "Senioritiy",
          type: "select",
          stateIndex: 1,
          options: options,
          placeholder: "Select Seniorities",
          inname: "seniorities_exclude",
          outname: "seniorities",
        },
        // {
        //   name: "Department",
        //   type: "input-toggle",
        //   stateIndex: 9,
        //   placeholder: "Enter Departmet",
        //   inname: "departments_exclude",
        //   outname: "departments",
        // },
        {
          name: "Department",
          type: "department-select-infinite",
          stateIndex: 9,
          options: Department,
          placeholder: "Enter Departmet",
          inname: "departments_exclude",
          outname: "departments",
        },
        // {
        //   name: "Company Detail",
        //   type: "input",
        //   stateIndex: 2,
        //   placeholder: "Enter Company Details",
        //   inname: "companyDetails",
        // },
        {
          name: "Company Name",
          type: "text-area",
          stateIndex: 3,
          placeholder: "Enter Company Name",
          inname: "companies_exclude",
          outname: "companies",
        },
        {
          name: "Company Domain",
          type: "text-area",
          stateIndex: 4,
          placeholder: "Enter Company Domain",
          inname: "companies_domain_exclude",
          outname: "companies_domain",
        },
        {
          name: "Industry",
          type: "select-infinite",
          stateIndex: 5,
          options: industries,
          placeholder: "Select an industry",
          inname: "industries_exclude",
          outname: "industries",
        },
        {
          name: "Employee Range",
          type: "select",
          stateIndex: 6,
          options: optionsemp,
          placeholder: "Select employee range",
          outname: "employee_ranges",
        },
        {
          name: "Revenue Range",
          type: "select",
          stateIndex: 7,
          options: optionsrev,
          placeholder: "Select revenue range",
          outname: "revenue_ranges",
        },
        {
          name: "Location",
          type: "location-select-infinite",
          stateIndex: 8,
          options: Address,
          placeholder: "Select an address",
          inname: "contact_address_exclude",
          outname: "contact_address",
        },
        // {
        //   name: "Keyword",
        //   type: "input-toggle",
        //   stateIndex: 9,
        //   placeholder: "Enter Keywords",
        //   inname: "keywords_exclude",
        //   outname: "keywords",
        // },
        // {
        //   name: "Technology",
        //   type: "input-toggle",
        //   stateIndex: 10,
        //   placeholder: "Enter Technologies",
        //   inname: "technologies_exclude",
        //   outname: "technologies",
        // },
      ];
    } else {
      // setFilterData([
      filterData = [
        // {
        //   name: "Company Detail",
        //   type: "input",
        //   stateIndex: 2,
        //   placeholder: "Enter Company Details",
        //   inname: "companyDetails",
        // },
        {
          name: "Company Name",
          type: "input-toggle",
          stateIndex: 3,
          placeholder: "Enter Company Name",
          inname: "companies_exclude",
          outname: "companies",
        },
        {
          name: "Company Domain",
          type: "input-toggle",
          stateIndex: 4,
          placeholder: "Enter Company Domain",
          inname: "companies_domain_exclude",
          outname: "companies_domain",
        },
        {
          name: "Industry",
          type: "select-infinite",
          stateIndex: 5,
          options: industries,
          placeholder: "Select an industry",
          inname: "industries_exclude",
          outname: "industries",
        },
        {
          name: "Employee Range",
          type: "select",
          stateIndex: 6,
          options: optionsemp,
          placeholder: "Select employee range",
          outname: "employee_ranges",
        },
        {
          name: "Revenue Range",
          type: "select",
          stateIndex: 7,
          options: optionsrev,
          placeholder: "Select revenue range",
          outname: "revenue_ranges",
        },
        {
          name: "Company Country/State/City",
          type: "select-infinite",
          stateIndex: 8,
          options: Address,
          placeholder: "Select an address",
          inname: "contact_address_exclude",
          outname: "contact_address",
        },
        // {
        //   name: "Keyword",
        //   type: "input-toggle",
        //   stateIndex: 9,
        //   placeholder: "Enter Keywords",
        //   inname: "keywords_exclude",
        //   outname: "keywords",
        // },
        // {
        //   name: "Technology",
        //   type: "input-toggle",
        //   stateIndex: 10,
        //   placeholder: "Enter Technologies",
        //   inname: "technologies_exclude",
        //   outname: "technologies",
        // },
      ];
    }
  }
  checkSearchType();
  // useEffect(() => {
  //   checkSearchType();
  // }, []);
  const [isChecked, setIsChecked] = useState(
    Array(filterData.length).fill(false)
  );
  const [isOpen, setIsOpen] = useState(Array(filterData.length).fill(false));

  const handleCheckboxChange = (index, event) => {
    const { name, checked } = event.target;

    // Update the isChecked state
    setIsChecked((prevState) =>
      prevState.map((state, i) => (i === index ? checked : state))
    );

    // Update the filters state
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked, // Set the specific filter's value
    }));
  };
  // Collapse all buttons by setting all values in the isOpen array to false
  const collapseAll = () => {
    setIsOpen(new Array(isOpen.length).fill(false));  // Set all buttons to collapsed
  };
  // Handle clearing all filters
  const handleClearFilter = () => {
    setFilters(initialFilters); // Reset filters to initial state
    setIsChecked(Array(filterData.length).fill(false)); // Reset isChecked state
    // setFilterData([]);
    // checkSearchType();
    collapseAll();
    setAppliedFilters(filters);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "8px",
      padding: "5px",
      margin: "0 10px",
      borderColor: "#ccc",
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      zIndex: 1, // Base z-index for the control
      "&:hover": {
        borderColor: "#007bff",
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "8px",
      margin: "0 10px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      zIndex: 1500, // High z-index to ensure dropdown menu appears above other content
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "10px",
      backgroundColor: state.isSelected ? "#007bff" : "#fff",
      color: state.isSelected ? "#fff" : "#333",
      "&:hover": {
        backgroundColor: "#007bff",
        color: "#fff",
      },
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 1500, // Ensure the menu portal has a high z-index for proper layering
    }),
  };

  const handleExportcall = async (
    filters,
    currentPage,
    DataToShow,
    setExportStatus,
    setExportFail
  ) => {
    try {
      const response = await apiClient.get(
        `/v1/leads/export-start?${new URLSearchParams({
          ...filters,
          per_page: DataToShow,
          page: currentPage,
        }).toString()}`
      );
      setexportstatus(true);

      // Hide the status after 3 seconds
      setTimeout(() => {
        setexportstatus(false);
      }, 3000);
    } catch (error) {
      setexportfail(true);

      // Hide the failure message after 3 seconds
      setTimeout(() => {
        setexportfail(false);
      }, 3000);

      console.error("Error exporting data:", error);
    }
  };

  const [isFilterSidebarOpen, setIsFilterSidebarOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModallead, setShowModallead] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenModalexport = () => setShowModalexport(true);
  const handleCloseModalexport = () => setShowModalexport(false);

  const handleOpenModallead = () => setShowModallead(true);
  const handleCloseModallead = () => setShowModallead(false);

  const handleFilterSidebar = () => {
    setIsFilterSidebarOpen(!isFilterSidebarOpen);
  };
  const closeSidebar = () => {
    setIsFilterSidebarOpen(false);
  };

  const toggleDropdown = (e) => {
    // Close all other dropdowns
    document.querySelectorAll(".dropdown").forEach((dropdown) => {
      if (dropdown !== e.currentTarget.parentElement) {
        dropdown.classList.remove("show");
      }
    });

    // Toggle the clicked dropdown
    e.currentTarget.parentElement.classList.toggle("show");
  };

  // Close the dropdown if clicked outside
  window.addEventListener("click", (e) => {
    if (
      !e.target.matches(".dropdown-toggle") &&
      !e.target.closest(".dropdown")
    ) {
      document.querySelectorAll(".dropdown").forEach((dropdown) => {
        dropdown.classList.remove("show");
      });
    }
  });

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handlebuttonclick = (button) => {
    setactiveBtn(activeBtn === button ? null : button);
  };

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <Sidebar />
      <Modal
        show={showModal}
        onClose={handleCloseModal}
        title="Add / Import Leads"
      >
        <ImportForm />
      </Modal>
      <Modal
        show={showModallead}
        onClose={handleCloseModallead}
        title="Add Leads"
      >
        <LeadForm />
      </Modal>
      <Modal
        show={ShowModalexport}
        onClose={handleCloseModalexport}
        title="Export Records"
      >
        <ExportForm filters={filters} AllLeads={checkedLeads} />
      </Modal>

      {exportstatus && (
        <div
          className={`export-message export-success ${
            exportstatus ? "show" : ""
          }`}
        >
          Data Exported Successfully
        </div>
      )}
      {exportfail && (
        <div
          className={`export-message export-fail ${exportfail ? "show" : ""}`}
        >
          Data Not Exported
        </div>
      )}
      <div className="home-body">
        {/* <span>
          Dashboard &gt; <b>Leads</b>
        </span> */}
        <section className="leads-header">
          <div className="lead-head">
            {/* <div className='head-content' onClick={handleOpenModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24">
	                    <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 14.899A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.242M12 12v9m-4-4l4 4l4-4" />
                    </svg> Import
                </div> */}
            <div className="head-content" onClick={handleOpenModalexport}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 256 256"
              >
                <path
                  fill="#000"
                  d="M196.49 151.51a12 12 0 0 1-17 17L168 157v51a12 12 0 0 1-24 0v-51l-11.51 11.52a12 12 0 1 1-17-17l32-32a12 12 0 0 1 17 0ZM160 36a92.08 92.08 0 0 0-81 48.37A68 68 0 1 0 72 220h28a12 12 0 0 0 0-24H72a44 44 0 0 1-1.81-87.95A91.7 91.7 0 0 0 68 128a12 12 0 0 0 24 0a68 68 0 1 1 132.6 21.29a12 12 0 1 0 22.8 7.51A92.06 92.06 0 0 0 160 36"
                />
              </svg>{" "}
              Exports
            </div>
            <div
              className="add-btn"
              style={{ display: "none" }}
              onClick={handleOpenModallead}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 24 24"
              >
                <path fill="purple" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z" />
              </svg>
            </div>
          </div>
        </section>

        <section className="leads-section">
          <section
            className={`filtration-section ${
              isFilterSidebarOpen ? "open" : ""
            }`}
          >
            {isFilterSidebarOpen && (
              <a onClick={closeSidebar}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#000"
                    d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
                  />
                </svg>
              </a>
            )}
            <div className="filter-tabs">
              <div
                className={`filter-tabs-content ${
                  activeTab === "Leads" ? "filter-tabs-content-active" : ""
                }`}
                onClick={() => handleTabClick("Leads")}
              >
                Leads
              </div>
              <div
                className={`filter-tabs-content ${
                  activeTab === "Company" ? "filter-tabs-content-active" : ""
                }`}
                onClick={() => handleTabClick("Company")}
              >
                Companies
              </div>
            </div>
            {/* <div className="filter-search">
                <input type="text" placeholder="search..." />
                <span className="input-toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512">
                        <path fill="#757575" d="M464 428L339.92 303.9a160.48 160.48 0 0 0 30.72-94.58C370.64 120.37 298.27 48 209.32 48S48 120.37 48 209.32s72.37 161.32 161.32 161.32a160.48 160.48 0 0 0 94.58-30.72L428 464ZM209.32 319.69a110.38 110.38 0 1 1 110.37-110.37a110.5 110.5 0 0 1-110.37 110.37"/>
                    </svg>
                </span>    
            </div> */}
            <div className="filter-footer">
              <div className="filter-clear" onClick={handleClearFilter}>
                <b>Clear</b>
              </div>
              <div className="filter-search-btn" onClick={applyFilters}>
                <b>Search</b>
              </div>
            </div>
            {filterData.map((filter, index) => (
              <div key={index}>
                <div
                  className="filter-button"
                  onClick={() => handleToggle(index)}
                >
                  <div className="filter-name">{filter.name}</div>
                  <span className="filter-toggle">
                    {isOpen[index] ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="purple"
                          fillRule="evenodd"
                          d="M1.75 8a.75.75 0 0 1 .75-.75h11a.75.75 0 0 1 0 1.5h-11A.75.75 0 0 1 1.75 8"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="purple"
                          d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                {isOpen[index] && (
                  <>
                    <div className="filter-content">
                      {filter.type === "input" && (
                        <input
                          type="text"
                          name={filter.outname}
                          placeholder={filter.placeholder}
                          className="filter-input"
                          onChange={handleInputChange}
                        />
                      )}
                      {filter.type === "double-input" && (
                        <div className="d-flex justify-content-between">
                          {" "}
                          <input
                            type="text"
                            name="first_name"
                            placeholder="Enter First Name"
                            className="filter-input m-1"
                            onChange={handleInputChange}
                          />
                          <input
                            type="text"
                            name="last_name"
                            placeholder="Enter Last Name"
                            className="filter-input m-1"
                            onChange={handleInputChange}
                          />{" "}
                        </div>
                      )}
                      {filter.type === "input-toggle" && (
                        <div>
                          <input
                            type="text"
                            name={filter.outname}
                            placeholder={filter.placeholder}
                            className="filter-input"
                            onChange={handleInputChange}
                          />
                          <div className="filter-toggle-switch p-2">
                            <input
                              type="checkbox"
                              id={`toggleSwitch${index}`}
                              name={filter.inname}
                              checked={isChecked[index] || false} // Ensure it's checked based on the state
                              onChange={(e) => handleCheckboxChange(index, e)}
                              className="toggle-input"
                            />
                            <label
                              htmlFor={`toggleSwitch${index}`}
                              name={filter.inname}
                              className="toggle-label"
                            ></label>
                          </div>
                        </div>
                      )}
                      {filter.type === "text-area" && (
                        <div>
                          <textarea
                            type="text"
                            name={filter.outname}
                            placeholder={filter.placeholder}
                            className="filter-input"
                            onChange={handleInputChange}
                          ></textarea>
                          <div className="filter-toggle-switch p-2">
                            Exclude <InfoTooltip message={`Exclude ${filter.name} from the result`} /> : 
                            <input
                              type="checkbox"
                              id={`toggleSwitch${index}`}
                              name={filter.inname}
                              checked={isChecked[index]}
                              onChange={(e) => handleCheckboxChange(index, e)}
                              className="toggle-input"
                            />
                            <label
                              htmlFor={`toggleSwitch${index}`}
                              name={filter.inname}
                              className="toggle-label"
                            ></label>
                          </div>
                        </div>
                      )}
                      {filter.type === "contact-info" && (
                        <div className="p-2 align-content-center">
                          <lable style={{ marginRight: "10px" }}>
                            Mobile (is Verified)
                          </lable>
                          <input
                            type="checkbox"
                            id={`toggleSwitch${index}`}
                            name={filter.inname}
                            checked={isChecked[index]}
                            onChange={(e) => handleCheckboxChange(index, e)}
                            className="toggle-input"
                            style={{ marginTop: "5px" }}
                          />
                          <label
                            htmlFor={`toggleSwitch${index}`}
                            name={filter.inname}
                            className="toggle-label"
                          ></label>

                          {/*                         
                        <lable>Email</lable>
                        <input type="text" name='email' placeholder="Enter Email Address" className="filter-input" onChange={handleInputChange} />
                          */}
                        </div>
                      )}
                    </div>
                    {filter.type === "select" && (
                      <><div style={{marginTop:"2px"}} ></div>
                        <Select
                          styles={customStyles}
                          options={filter.options}
                          name={filter.outname}
                          placeholder={filter.placeholder}
                          isSearchable={true}
                          closeMenuOnSelect={false}
                          onChange={handleSelectChange}
                          isMulti={true} // Enable multiple selection
                        />
                        {/* <div className="filter-toggle-switch p-2">
                          <input
                            type="checkbox"
                            id={`toggleSwitch${index}`}
                            name={filter.inname}
                            checked={isChecked[index]}
                            onChange={(e) => handleCheckboxChange(index, e)}
                            className="toggle-input"
                          />
                          <label
                            htmlFor={`toggleSwitch${index}`}
                            name={filter.inname}
                            className="toggle-label"
                          ></label>
                        </div> */}
                      </>
                    )}
                    {filter.type === "select-infinite" && (
                      <>
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={
                            filter.name.includes("Industry")
                              ? loadMoreindustries
                              : loadMoreAddress
                          }
                          hasMore={hasMore}
                        >
                          <Select
                            styles={customStyles}
                            options={filter.options}
                            name={filter.outname}
                            placeholder={filter.placeholder}
                            isSearchable={true}
                            isLoading={loading}
                            isMulti={true} // Enable multiple selection
                            closeMenuOnSelect={false}
                            menuPortalTarget={document.body} // Optional: ensure menu is displayed in the correct layer
                            onChange={handleSelectChange}
                          />
                        </InfiniteScroll>
                        <div className="filter-toggle-switch p-2">
                          Exclude <InfoTooltip message={`Exclude ${filter.name} from the result`} /> : 
                          <input
                            type="checkbox"
                            id={`toggleSwitch${index}`}
                            name={filter.inname}
                            checked={isChecked[index]}
                            onChange={(e) => handleCheckboxChange(index, e)}
                            className="toggle-input"
                          />
                          <label
                            htmlFor={`toggleSwitch${index}`}
                            name={filter.inname}
                            className="toggle-label"
                          ></label>
                        </div>
                      </>
                    )}
                    {filter.type === "location-select-infinite" && (
                      <>
                        <lable style={{ paddingLeft: "10px" }}>
                          Contact Location
                        </lable>
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={loadMoreAddress}
                          hasMore={hasMore}
                        >
                          <Select
                            styles={customStyles}
                            options={Address}
                            name="contact_address"
                            placeholder="Search location"
                            isSearchable={true}
                            isLoading={loadingAddress}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            menuPortalTarget={document.body}
                            onInputChange={handleSearchChange} // Trigger search on input change
                            onChange={handleSelectChange}
                          />
                        </InfiniteScroll>
                        <lable style={{ paddingLeft: "10px" }}>
                          Company Location
                        </lable>
                        <InfiniteScroll
                          pageStart={0}
                          loadMore={loadMoreAddress}
                          hasMore={hasMore}
                        >
                          <Select
                            styles={customStyles}
                            options={Address}
                            name="company_address"
                            placeholder="Search location"
                            isSearchable={true}
                            isLoading={loadingAddress}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            menuPortalTarget={document.body}
                            onInputChange={handleSearchChange} // Trigger search on input change
                            onChange={handleSelectChange}
                          />
                        </InfiniteScroll>
                        {/* <div className="filter-toggle-switch p-2">
                          <input
                            type="checkbox"
                            id={`toggleSwitch${index}`}
                            name={filter.inname}
                            checked={isChecked[index]}
                            onChange={(e) => handleCheckboxChange(index, e)}
                            className="toggle-input"
                          />
                          <label
                            htmlFor={`toggleSwitch${index}`}
                            name={filter.inname}
                            className="toggle-label"
                          ></label>
                        </div> */}
                      </>
                    )}
                    {filter.type === "department-select-infinite" && (
                      <>
                      <InfiniteScroll
                        pageStart={0}
                        loadMore={
                          filter.name.includes("Industry")
                            ? loadMoreindustries
                            : loadMoreAddress
                        }
                        hasMore={hasMore}
                      >
                        <Select
                          styles={customStyles}
                          options={filter.options}
                          name={filter.outname}
                          placeholder={filter.placeholder}
                          isSearchable={true}
                          isLoading={loading}
                          isMulti={true} // Enable multiple selection
                          closeMenuOnSelect={false}
                          menuPortalTarget={document.body} // Optional: ensure menu is displayed in the correct layer
                          onInputChange={handleSearchDepartment} // Trigger search on input change
                          onChange={handleSelectChange}
                        />
                      </InfiniteScroll>
                      {/* <div className="filter-toggle-switch p-2">
                        <input
                          type="checkbox"
                          id={`toggleSwitch${index}`}
                          name={filter.inname}
                          checked={isChecked[index]}
                          onChange={(e) => handleCheckboxChange(index, e)}
                          className="toggle-input"
                        />
                        <label
                          htmlFor={`toggleSwitch${index}`}
                          name={filter.inname}
                          className="toggle-label"
                        ></label>
                      </div> */}
                    </>
                    )}
                  </>
                )}
              </div>
            ))}
          </section>
          <section className="table-section">
            <div className="d-flex justify-content-between flex-wrap p-2">
              <div className=" table-btn">
                <div className="table-btn-filter">
                  Show:
                  <select
                    value={DataToShow}
                    className="table-select-filter"
                    onChange={(e) => setDataToShow(e.target.value)}
                  >
                    <option value={10}>10</option>
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
              <div className=" table-btn-sort">
                <div className="table-sort-filter">
                  Sort By:
                  <select
                    value={sortOrder}
                    className="table-select-filter"
                    onChange={(e) => setSortOrder(e.target.value)}
                  >
                    <option value="Newest">Newest</option>
                    <option value="Oldest">Oldest</option>
                  </select>
                </div>
                <button
                  className="filter-toggle-btn"
                  onClick={handleFilterSidebar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#505050"
                      d="M14 12v7.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0l-2.01-2.01a.99.99 0 0 1-.29-.83V12h-.03L4.21 4.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4L14.03 12z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            {activeTab === "Leads" && (
              <>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            checked={isHeaderChecked}
                            onChange={handleHeaderCheckboxChange}
                          />
                        </th>
                        <th className="table-heading truncate">Name</th>
                        <th className="table-heading truncate">Title</th>
                        {/* <th className="table-heading truncate">Phone Number</th> */}
                        {/* <th className="table-heading truncate">Email</th> */}
                        <th className="table-heading truncate">Country</th>
                        {/* <th className="table-heading truncate">Address</th> */}
                        <th className="table-heading truncate">Company</th>
                        {/* <th className="table-heading truncate">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {loadingLeads ? (
                        <tr>
                          <td colSpan="7" className="loading-cell">
                            <div className="loading-text">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                              >
                                <circle cx="12" cy="3" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale0"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="0;svgSpinners6DotsScale2.end-0.5s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="16.5"
                                  cy="4.21"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale1"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale0.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="7.5" cy="4.21" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale2"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale4.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="19.79"
                                  cy="7.5"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale3"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale1.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="4.21" cy="7.5" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale4"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale6.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="21" cy="12" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale5"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale3.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="3" cy="12" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale6"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale8.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="19.79"
                                  cy="16.5"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale7"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale5.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="4.21"
                                  cy="16.5"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale8"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScalea.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="16.5"
                                  cy="19.79"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale9"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale7.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="7.5"
                                  cy="19.79"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScalea"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScaleb.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="12" cy="21" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScaleb"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale9.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                              </svg>
                            </div>
                          </td>
                        </tr>
                      ) : (
                        leads.map((lead) => (
                          <tr key={lead.id}>
                            <td className="table-body">
                              <input
                                type="checkbox"
                                checked={checkedLeads.includes(lead.id)}
                                onChange={() => handleCheckboxChange1(lead.id)}
                              />
                            </td>
                            <td className="table-body truncate">
                              {`${lead && lead.first_name} ${
                                lead && lead.last_name
                              }`}

                              <div className="social-icon dropdown-item1" style={{justifyContent:"center"}}>
                                <div className="icon-person">
                                  <a
                                    target="_blank"
                                    href={lead && lead.linkedin_url}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="-2 -2 24 24"
                                    >
                                      <g fill="#2f88ff">
                                        <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457c-.592 0-.945.398-1.1.784c-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066c1.41 0 2.468.922 2.468 2.902M6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115c-.014-.632-.465-1.114-1.199-1.114m-1.086 9.556h2.144V8.38H5.127z" />
                                        <path d="M10 18a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10s-4.477 10-10 10" />
                                      </g>
                                    </svg>
                                  </a>
                                </div>
                                <div className="icon-person">
                                  <a
                                    target="_blank"
                                    href={`mailto:${lead && lead.email}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 36 36"
                                    >
                                      <path
                                        fill="#2f88ff"
                                        d="M32.33 6a2 2 0 0 0-.41 0h-28a2 2 0 0 0-.53.08l14.45 14.39Z"
                                        class="clr-i-solid clr-i-solid-path-1"
                                      />
                                      <path
                                        fill="#2f88ff"
                                        d="m33.81 7.39l-14.56 14.5a2 2 0 0 1-2.82 0L2 7.5a2 2 0 0 0-.07.5v20a2 2 0 0 0 2 2h28a2 2 0 0 0 2-2V8a2 2 0 0 0-.12-.61M5.3 28H3.91v-1.43l7.27-7.21l1.41 1.41Zm26.61 0h-1.4l-7.29-7.23l1.41-1.41l7.27 7.21Z"
                                        class="clr-i-solid clr-i-solid-path-2"
                                      />
                                      <path fill="none" d="M0 0h36v36H0z" />
                                    </svg>
                                  </a>
                                </div>
                                <div>
                                  <a
                                    target="_blank"
                                    href={`tel:${lead && lead.work_phone}`}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 56 56"
                                    >
                                      <path
                                        fill="#2f88ff"
                                        d="M28 51.906c13.055 0 23.906-10.828 23.906-23.906c0-13.055-10.875-23.906-23.93-23.906C14.899 4.094 4.095 14.945 4.095 28c0 13.078 10.828 23.906 23.906 23.906m0-3.984C16.937 47.922 8.1 39.062 8.1 28c0-11.04 8.813-19.922 19.876-19.922c11.039 0 19.921 8.883 19.945 19.922c.023 11.063-8.883 19.922-19.922 19.922m-5.625-14.39c5.46 5.484 12.422 8.952 16.547 4.85a3.54 3.54 0 0 0 .398-.468c1.078-1.195 1.383-2.789 0-3.82c-1.078-.774-2.226-1.57-4.172-2.93c-1.359-.96-2.25-.727-3.304.328l-.938.914c-.281.258-.726.235-1.031.047c-.82-.492-2.203-1.594-3.516-2.906c-1.289-1.29-2.46-2.742-2.906-3.516c-.164-.258-.281-.68.047-.984l.914-.985c1.055-1.101 1.289-1.945.305-3.328l-2.883-4.078c-.985-1.383-2.414-1.125-3.985.047c-.117.094-.21.188-.304.281c-4.125 4.125-.633 11.086 4.828 16.547"
                                      />
                                    </svg>
                                  </a>
                                </div>
                              </div>
                            </td>
                            <td className="table-body align-content-center">
                              <div className="tooltip2">
                                <div className="truncate">
                                  {lead && lead.title}
                                </div>
                                <span className="tooltiptext2">
                                  {lead && lead.title}
                                </span>
                              </div>
                            </td>
                            {/* <td className="table-body truncate">{lead && lead.work_phone}</td> */}
                            {/* <td className="table-body align-content-center">
                            <div className="tooltip2"><div className="truncate">{lead && lead.email}</div>
                              <span className="tooltiptext2">{lead && lead.email}</span>
                            </div>  
                        </td> */}
                            <td className="table-body truncate">
                              {lead && lead.country && lead.country.name}
                            </td>
                            {/* <td className="table-body align-content-center">
                            <div className="tooltip2"><div className="truncate">{lead && lead.city && lead.city.name}{lead && lead.city && (',')}{lead && lead.state && lead.state.name}</div>
                              <span className="tooltiptext2">{lead && lead.city && lead.city.name}{lead && lead.city && (',')}{lead && lead.state && lead.state.name}</span>
                            </div> 
                          
                        </td> */}
                            <td className="table-body ">
                              <div className="truncate-data">
                                <div className="company-img">
                                  <img
                                    className="table-image"
                                    src={`https://logo.clearbit.com/${
                                      lead &&
                                      lead.company &&
                                      lead.company.website
                                    }`}
                                    alt="profile"
                                  />
                                </div>
                                <div>
                                  <div className="tooltip2">
                                    <div className="truncate-data-text">
                                      {lead &&
                                        lead.company &&
                                        lead.company.name}
                                    </div>
                                    <span className="tooltiptext2">
                                      {lead &&
                                        lead.company &&
                                        lead.company.name}
                                    </span>
                                  </div>
                                  {/* <div className="truncate-data-text"> 
                              {lead.company.name}<br/> */}
                                  <div className="social-icon1 dropdown-item1">
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={
                                          lead &&
                                          lead.company &&
                                          lead.company.linkedin_url
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="-2 -2 24 24"
                                        >
                                          <g fill="#2f88ff">
                                            <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457c-.592 0-.945.398-1.1.784c-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066c1.41 0 2.468.922 2.468 2.902M6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115c-.014-.632-.465-1.114-1.199-1.114m-1.086 9.556h2.144V8.38H5.127z" />
                                            <path d="M10 18a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10s-4.477 10-10 10" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={
                                          lead &&
                                          lead.company &&
                                          lead.company.website
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                        >
                                          <g fill="#2f88ff">
                                            <path
                                              fill-rule="evenodd"
                                              d="M1.25 10a8.75 8.75 0 1 0 17.5 0a8.75 8.75 0 0 0-17.5 0m16 0a7.25 7.25 0 1 1-14.5 0a7.25 7.25 0 0 1 14.5 0"
                                              clip-rule="evenodd"
                                            />
                                            <path
                                              fill-rule="evenodd"
                                              d="M6.25 10c0 4.522 1.491 8.25 3.75 8.25s3.75-3.728 3.75-8.25S12.259 1.75 10 1.75S6.25 5.478 6.25 10m6 0c0 3.762-1.195 6.75-2.25 6.75S7.75 13.762 7.75 10S8.945 3.25 10 3.25s2.25 2.988 2.25 6.75"
                                              clip-rule="evenodd"
                                            />
                                            <path d="m3.602 5.467l1.006-1.112c.1.09.209.18.325.267c1.271.952 3.3 1.54 5.515 1.54c1.891 0 3.653-.427 4.931-1.158c.308-.176.582-.367.819-.57l.974 1.141a6.73 6.73 0 0 1-1.048.73c-1.516.868-3.534 1.356-5.676 1.356c-2.522 0-4.865-.678-6.415-1.839a6.063 6.063 0 0 1-.431-.355m0 9.082l1.006 1.112c.1-.091.209-.18.325-.267c1.271-.952 3.3-1.54 5.515-1.54c1.891 0 3.653.427 4.931 1.158c.308.176.582.367.819.57l.974-1.141a6.841 6.841 0 0 0-1.048-.73c-1.516-.868-3.534-1.356-5.676-1.356c-2.522 0-4.865.678-6.415 1.839a6.06 6.06 0 0 0-.431.355M1.75 10.75v-1.5h16.5v1.5z" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={
                                          lead &&
                                          lead.company &&
                                          lead.company.twitter_url
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 32 32"
                                        >
                                          <g fill="#2f88ff">
                                            <path d="M16-.034C7.159-.034-.035 7.158-.035 16S7.159 32.034 16 32.034S32.035 24.842 32.035 16S24.841-.034 16-.034m0 31C7.748 30.966 1.035 24.252 1.035 16S7.748 1.034 16 1.034S30.965 7.748 30.965 16S24.252 30.966 16 30.966" />
                                            <path d="M24.794 11.046a3.73 3.73 0 0 0 .273-.643a.535.535 0 0 0-.781-.623a6.033 6.033 0 0 1-1.646.673a3.831 3.831 0 0 0-2.562-.987a3.82 3.82 0 0 0-3.814 3.973a8.821 8.821 0 0 1-5.735-3.178a.536.536 0 0 0-.876.07a3.813 3.813 0 0 0-.066 3.721a.628.628 0 0 0-.214.078a.571.571 0 0 0-.264.481c0 1.135.505 2.177 1.319 2.885l-.035.035a.534.534 0 0 0-.108.518a3.816 3.816 0 0 0 2.184 2.365a6.134 6.134 0 0 1-3.406.579a.545.545 0 0 0-.566.352a.536.536 0 0 0 .215.63a9.841 9.841 0 0 0 5.319 1.559c6.18 0 9.874-5.02 9.874-9.873l-.001-.164a7.122 7.122 0 0 0 1.541-1.66a.535.535 0 0 0-.651-.791m-1.745 1.755a.535.535 0 0 0-.222.461c.006.132.009.266.009.398c0 4.328-3.293 8.805-8.804 8.805a8.76 8.76 0 0 1-2.887-.488a7.073 7.073 0 0 0 3.045-1.39a.535.535 0 0 0-.32-.955a2.752 2.752 0 0 1-2.194-1.162c.253-.017.501-.059.742-.124a.534.534 0 0 0-.036-1.04a2.741 2.741 0 0 1-2.081-1.905c.266.067.539.106.812.115c.235-.001.453-.145.526-.371s-.013-.475-.212-.607a2.746 2.746 0 0 1-1.143-2.945a9.883 9.883 0 0 0 6.568 2.972c.176.001.33-.062.437-.191s.148-.3.11-.464a2.75 2.75 0 0 1 4.682-2.506a.535.535 0 0 0 .494.159c.165-.033.328-.071.489-.115a2.538 2.538 0 0 1-.226.151a.535.535 0 0 0 .338.988l.182-.023a5.605 5.605 0 0 1-.309.237" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={lead.company.facebook_url}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                        >
                                          <g fill="none" fill-rule="evenodd">
                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                            <path
                                              fill="#2f88ff"
                                              d="M4 12a8 8 0 1 1 9 7.938V14h2a1 1 0 1 0 0-2h-2v-2a1 1 0 0 1 1-1h.5a1 1 0 1 0 0-2H14a3 3 0 0 0-3 3v2H9a1 1 0 1 0 0 2h2v5.938A8 8 0 0 1 4 12m8 10c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"
                                            />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            {/* <td className="table-body action-cell">
                              <div className="dropdown1">
                                <button
                                  className="dropdown-toggle1"
                                  onClick={(e) => toggleDropdown(e)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fill="black"
                                      d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm-7 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 3 7.8zm14 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 17 7.8z"
                                    />
                                  </svg>
                                </button>
                                <div className="dropdown-menu1">
                                  <a
                                    href="#edit"
                                    className="dropdown-item1 dropdown-text"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                    >
                                      <g
                                        fill="none"
                                        stroke="black"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      >
                                        <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                        <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                                      </g>
                                    </svg>{" "}
                                    Edit
                                  </a>
                                </div>
                              </div>
                            </td> */}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-footer">
                  <div className="paginate-footer-content">
                    {/* Showing {currentPage} Page of {formatNumber(totalPages)} Pages */}
                    {(DataToShow)*(currentPage-1)+1} - {(DataToShow)*(currentPage)} of {formatNumber(totalPages*DataToShow)}
                  </div>
                  <div>
                    <PaginationComponent
                      pageCount={totalPages}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                </div>
              </>
            )}
            {activeTab === "Company" && (
              <>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <input type="checkbox" />
                        </th>
                        <th className="table-heading truncate">Name</th>
                        <th className="table-heading truncate">
                          Employee Count
                        </th>
                        <th className="table-heading truncate">Phone Number</th>
                        <th className="table-heading truncate">Reveune</th>
                        <th className="table-heading truncate">Country</th>
                        <th className="table-heading truncate">Address</th>
                        <th className="table-heading truncate">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loadingLeads ? (
                        <tr>
                          <td colSpan="7" className="loading-cell">
                            <div className="loading-text">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="48"
                                height="48"
                                viewBox="0 0 24 24"
                              >
                                <circle cx="12" cy="3" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale0"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="0;svgSpinners6DotsScale2.end-0.5s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="16.5"
                                  cy="4.21"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale1"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale0.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="7.5" cy="4.21" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale2"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale4.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="19.79"
                                  cy="7.5"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale3"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale1.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="4.21" cy="7.5" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale4"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale6.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="21" cy="12" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale5"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale3.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="3" cy="12" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScale6"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale8.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="19.79"
                                  cy="16.5"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale7"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale5.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="4.21"
                                  cy="16.5"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale8"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScalea.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="16.5"
                                  cy="19.79"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScale9"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale7.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle
                                  cx="7.5"
                                  cy="19.79"
                                  r="0"
                                  fill="#5800ac"
                                >
                                  <animate
                                    id="svgSpinners6DotsScalea"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScaleb.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                                <circle cx="12" cy="21" r="0" fill="#5800ac">
                                  <animate
                                    id="svgSpinners6DotsScaleb"
                                    fill="freeze"
                                    attributeName="r"
                                    begin="svgSpinners6DotsScale9.begin+0.1s"
                                    calcMode="spline"
                                    dur="0.6s"
                                    keySplines="0,1,0,1;.53,0,.61,.73"
                                    keyTimes="0;.2;1"
                                    values="0;2;0"
                                  />
                                </circle>
                              </svg>
                            </div>
                          </td>
                        </tr>
                      ) : companies.length > 0 ? (
                        companies.map((company) => (
                          <tr key={company.id}>
                            <td className="table-body">
                              <input type="checkbox" />
                            </td>
                            <td className="table-body">
                              <div className="truncate-data">
                                <div className="company-img">
                                  <img
                                    className="table-image"
                                    src={`https://logo.clearbit.com/${
                                      company && company.website
                                    }`}
                                    alt="profile"
                                  />
                                </div>
                                <div>
                                  <div className="tooltip2">
                                    <div className="truncate-data-text">
                                      {company.name}
                                    </div>
                                    <span className="tooltiptext2">
                                      {company && company.name}
                                    </span>
                                  </div>
                                  {/* <div className="truncate-data-text"> 
                            {company.name}<br/> */}
                                  <div className="social-icon1 dropdown-item1">
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={company && company.linkedin_url}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="-2 -2 24 24"
                                        >
                                          <g fill="#2f88ff">
                                            <path d="M15 11.13v3.697h-2.143v-3.45c0-.866-.31-1.457-1.086-1.457c-.592 0-.945.398-1.1.784c-.056.138-.071.33-.071.522v3.601H8.456s.029-5.842 0-6.447H10.6v.913l-.014.021h.014v-.02c.285-.44.793-1.066 1.932-1.066c1.41 0 2.468.922 2.468 2.902M6.213 5.271C5.48 5.271 5 5.753 5 6.385c0 .62.466 1.115 1.185 1.115h.014c.748 0 1.213-.496 1.213-1.115c-.014-.632-.465-1.114-1.199-1.114m-1.086 9.556h2.144V8.38H5.127z" />
                                            <path d="M10 18a8 8 0 1 0 0-16a8 8 0 0 0 0 16m0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10s-4.477 10-10 10" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={company && company.website}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                        >
                                          <g fill="#2f88ff">
                                            <path
                                              fill-rule="evenodd"
                                              d="M1.25 10a8.75 8.75 0 1 0 17.5 0a8.75 8.75 0 0 0-17.5 0m16 0a7.25 7.25 0 1 1-14.5 0a7.25 7.25 0 0 1 14.5 0"
                                              clip-rule="evenodd"
                                            />
                                            <path
                                              fill-rule="evenodd"
                                              d="M6.25 10c0 4.522 1.491 8.25 3.75 8.25s3.75-3.728 3.75-8.25S12.259 1.75 10 1.75S6.25 5.478 6.25 10m6 0c0 3.762-1.195 6.75-2.25 6.75S7.75 13.762 7.75 10S8.945 3.25 10 3.25s2.25 2.988 2.25 6.75"
                                              clip-rule="evenodd"
                                            />
                                            <path d="m3.602 5.467l1.006-1.112c.1.09.209.18.325.267c1.271.952 3.3 1.54 5.515 1.54c1.891 0 3.653-.427 4.931-1.158c.308-.176.582-.367.819-.57l.974 1.141a6.73 6.73 0 0 1-1.048.73c-1.516.868-3.534 1.356-5.676 1.356c-2.522 0-4.865-.678-6.415-1.839a6.063 6.063 0 0 1-.431-.355m0 9.082l1.006 1.112c.1-.091.209-.18.325-.267c1.271-.952 3.3-1.54 5.515-1.54c1.891 0 3.653.427 4.931 1.158c.308.176.582.367.819.57l.974-1.141a6.841 6.841 0 0 0-1.048-.73c-1.516-.868-3.534-1.356-5.676-1.356c-2.522 0-4.865.678-6.415 1.839a6.06 6.06 0 0 0-.431.355M1.75 10.75v-1.5h16.5v1.5z" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={company && company.twitter_url}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 32 32"
                                        >
                                          <g fill="#2f88ff">
                                            <path d="M16-.034C7.159-.034-.035 7.158-.035 16S7.159 32.034 16 32.034S32.035 24.842 32.035 16S24.841-.034 16-.034m0 31C7.748 30.966 1.035 24.252 1.035 16S7.748 1.034 16 1.034S30.965 7.748 30.965 16S24.252 30.966 16 30.966" />
                                            <path d="M24.794 11.046a3.73 3.73 0 0 0 .273-.643a.535.535 0 0 0-.781-.623a6.033 6.033 0 0 1-1.646.673a3.831 3.831 0 0 0-2.562-.987a3.82 3.82 0 0 0-3.814 3.973a8.821 8.821 0 0 1-5.735-3.178a.536.536 0 0 0-.876.07a3.813 3.813 0 0 0-.066 3.721a.628.628 0 0 0-.214.078a.571.571 0 0 0-.264.481c0 1.135.505 2.177 1.319 2.885l-.035.035a.534.534 0 0 0-.108.518a3.816 3.816 0 0 0 2.184 2.365a6.134 6.134 0 0 1-3.406.579a.545.545 0 0 0-.566.352a.536.536 0 0 0 .215.63a9.841 9.841 0 0 0 5.319 1.559c6.18 0 9.874-5.02 9.874-9.873l-.001-.164a7.122 7.122 0 0 0 1.541-1.66a.535.535 0 0 0-.651-.791m-1.745 1.755a.535.535 0 0 0-.222.461c.006.132.009.266.009.398c0 4.328-3.293 8.805-8.804 8.805a8.76 8.76 0 0 1-2.887-.488a7.073 7.073 0 0 0 3.045-1.39a.535.535 0 0 0-.32-.955a2.752 2.752 0 0 1-2.194-1.162c.253-.017.501-.059.742-.124a.534.534 0 0 0-.036-1.04a2.741 2.741 0 0 1-2.081-1.905c.266.067.539.106.812.115c.235-.001.453-.145.526-.371s-.013-.475-.212-.607a2.746 2.746 0 0 1-1.143-2.945a9.883 9.883 0 0 0 6.568 2.972c.176.001.33-.062.437-.191s.148-.3.11-.464a2.75 2.75 0 0 1 4.682-2.506a.535.535 0 0 0 .494.159c.165-.033.328-.071.489-.115a2.538 2.538 0 0 1-.226.151a.535.535 0 0 0 .338.988l.182-.023a5.605 5.605 0 0 1-.309.237" />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                    <div className="social-icon-deep">
                                      <a
                                        target="_blank"
                                        href={company && company.facebook_url}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                        >
                                          <g fill="none" fill-rule="evenodd">
                                            <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                                            <path
                                              fill="#2f88ff"
                                              d="M4 12a8 8 0 1 1 9 7.938V14h2a1 1 0 1 0 0-2h-2v-2a1 1 0 0 1 1-1h.5a1 1 0 1 0 0-2H14a3 3 0 0 0-3 3v2H9a1 1 0 1 0 0 2h2v5.938A8 8 0 0 1 4 12m8 10c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"
                                            />
                                          </g>
                                        </svg>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="table-body truncate">
                              {company && company.employee_count}
                            </td>
                            <td className="table-body truncate">
                              {company && company.phone}
                            </td>
                            <td className="table-body truncate">
                              {company && company.revenue_annual}
                            </td>
                            <td className="table-body truncate">
                              {company &&
                                company.country &&
                                company.country.name}
                            </td>
                            <td className="table-body">
                              <div className="tooltip1">
                                <div className="truncate">
                                  {company && company.address}
                                </div>
                                <span className="tooltiptext1">
                                  {company && company.address}
                                </span>
                              </div>
                            </td>
                            <td className="table-body action-cell ">
                              <div className="dropdown1">
                                <button
                                  className="dropdown-toggle1"
                                  onClick={(e) => toggleDropdown(e)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                  >
                                    <path
                                      fill="black"
                                      d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm-7 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 3 7.8zm14 0a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 17 7.8z"
                                    />
                                  </svg>
                                </button>
                                <div className="dropdown-menu1">
                                  <a
                                    href="#edit"
                                    className="dropdown-item1 dropdown-text"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                    >
                                      <g
                                        fill="none"
                                        stroke="black"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                      >
                                        <path d="M12 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
                                        <path d="M18.375 2.625a1 1 0 0 1 3 3l-9.013 9.014a2 2 0 0 1-.853.505l-2.873.84a.5.5 0 0 1-.62-.62l.84-2.873a2 2 0 0 1 .506-.852z" />
                                      </g>
                                    </svg>{" "}
                                    Edit
                                  </a>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <div>No Record to Show</div>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-footer">
                  <div className="paginate-footer-content">
                    {/* Showing {currentPage} Page of {formatNumber(totalPagescompany)} Pages */}
                    {(DataToShow)*(currentPage-1)+1} - {(DataToShow)*(currentPage)} of {formatNumber(totalPagescompany*DataToShow)}
                  </div>
                  <div>
                    <PaginationComponent
                      pageCount={totalPagescompany}
                      handlePageClick={handlePageClick}
                    />
                  </div>
                </div>
              </>
            )}
          </section>
        </section>
      </div>
    </div>
  );
}

export default Leads;
